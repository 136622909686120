import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { BlogService } from '../utilities/servicios/blog.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { GlobalUtilities } from '../utilities/clases/global_utilities';
import { Meta, Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-detalle-blog',
  templateUrl: './detalle-blog.component.html',
  styleUrls: ['./detalle-blog.component.scss']
})
export class DetalleBlogComponent implements OnInit {
  blog: any;
  relacionados: any;
  isBrowser;
  titulo;
  noticias: any;
  utilities;
  config;
  GlobalUtilities = new GlobalUtilities();

  slidePrincipalConfig: any = {
    fade: true,
        cssEase: 'linear',
    speed: 500,  // this is the line that matters
    bullets: true,
    arrows: false,
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blogService: BlogService,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.getBlog();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    this.getBlog();
  }

  getBlog(): void {
    let titulo = this.route.snapshot.paramMap.get('titulo');
    this.blogService.getBlogByName(String(titulo))
      .subscribe(data => {
        if(data.blog.length > 0 && data.blog[0].activo ){
          this.blog = data.blog[0];
          this.relacionados = data.blog[0].relacionados.filter((items) => items.activo === true);

          this.meta.updateTag({ name: 'og:author', property: 'og:author', content: 'Klockner' });
          this.meta.updateTag({ name: 'og:description', property: 'og:description', content: this.blog.meta.meta_description });
          this.meta.updateTag({ name: 'og:type', property: 'og:type', content: 'article' });
          this.meta.updateTag({ name: 'og:title', property: 'og:title', content: this.blog.meta.meta_title });
          this.meta.updateTag({ name: 'og:url', property: 'og:url', content: this.blog.meta.meta_url });
          this.meta.updateTag({ name: 'og:image', property: 'og:image', content: 'https://api.klockner.es' + this.blog.photo[0] });
      
          this.meta.updateTag({ name: 'twitter:card', property: 'twitter:card', content: 'summary' });
          this.meta.updateTag({ name: 'twitter:description', property: 'twitter:description', content: this.blog.meta.meta_description });
          this.meta.updateTag({ name: 'twitter:image', property: 'twitter:image', content: 'https://api.klockner.es' + this.blog.photo[0] });
          this.meta.updateTag({ name: 'twitter:url', property: 'twitter:url', content: this.blog.meta.meta_url });
          this.meta.updateTag({ name: 'twitter:title', property: 'twitter:title', content: this.blog.meta.meta_title });
        }else{
          window.location.href = '/404';
        }
      });
  }
}

import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { PonenteService } from "../../../utilities/servicios/ponente.service";

import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";

@Component({
  selector: 'app-edit-ponente',
  templateUrl: './edit-ponente.component.html',
  styleUrls: ['./edit-ponente.component.scss']
})
export class EditPonenteComponent implements OnInit {
  ponente: any;
  selectedFiles = [];
  isBrowser;

  //Para poner la información en la dataTable
  ponenteForm = this.fb.group({
    nombre: ['', Validators.required],
    descripcion: ['', Validators.required],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private ponenteService: PonenteService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private elRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  onFileChanged(event, i) {
    if (this.isBrowser) {
      let fotoEvento = event.target.files[0];
      this.selectedFiles[i] = fotoEvento;

      let div = this.elRef.nativeElement.querySelector('#fotos' + i);
      div.innerHTML = '';

      div.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 id='div_photo_product_" + i + "' class='div_photo_product card_separator mat-card ng-star-inserted'>" +
        "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
        "<i _ngcontent-c10 class='fas fa-times remove_related' style='cursor : pointer;' id='borrar_" + i + "'></i>" +
        "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[0]) + ")' readonly='readonly'></div>" +
        "</mat-card-content>" +
        "</mat-card>");

      this.elRef.nativeElement.querySelector('#borrar_' + i).addEventListener('click', this.removeImage.bind(this, i, false));
    }
  }

  clickFunction(i) {
    let element: HTMLElement = document.getElementById('presentacion' + i) as HTMLElement;
    element.click();
  }

  removeImage(this, i, foto) {
    if (this.isBrowser) {
      $('#div_photo_product_' + i).remove();
      $('#presentacion' + i).val('');
      $('#photoName' + i).val('');
      delete this.selectedFiles[i]
    }
  }

  ngOnInit() {
    this.getPonente();
  }

  getPonente(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.ponenteService.getPonente(String(id))
      .subscribe(data => {
        this.ponente = data.ponente;
        this.ponenteForm.controls['nombre'].setValue(data.ponente.nombre);
        this.ponenteForm.controls['descripcion'].setValue(data.ponente.descripcion);
      });
  }

  onSubmit() {
    var nombreImagen : any = "";

    if (this.selectedFiles.length > 0) {
      if(this.selectedFiles[0] != undefined){
        nombreImagen = this.selectedFiles[0].name;
      }
    }

    if(nombreImagen == ''){
      nombreImagen = $("#photoName0").val();
    }

    if(nombreImagen == undefined){
      nombreImagen = '';
    }

    var input = new FormData();
    input.append('ponente', JSON.stringify(this.ponenteForm.value));
    input.append('fotos', nombreImagen);

    if (this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        input.append('image[]', file);
      });
    }

    let id = this.route.snapshot.paramMap.get('id');
    this.ponenteService.updatePonente(input, id).subscribe(response => {
      this.openSnackBar('Ponente modificado correctamente', 'Aceptar');
    }, err => {
      this.openSnackBar('Ha ocurrido un error modificando el ponente', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}


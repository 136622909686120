import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {

  private faqURL = 'https://api.klockner.es/faq';
  private faqsURL = 'https://api.klockner.es/faqs';
  private changeactiveURL = 'https://api.klockner.es/faq/active/';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { 

  }

  getFaqs (): Observable<any> {
    return this.http.get<any>(this.faqsURL)
  }

  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }

  updateFaq (payload, id) {
    return this.http.put(this.faqURL + "/" + id , payload, this.httpOptions)
  }
  
  createFaq (payload) {
    return this.http.post(this.faqURL, payload, this.httpOptions)
  }

  getFaq (id: String): Observable<any> {
    const url = `${this.faqURL}/${id}`;
    return this.http.get<any>(url, {
      withCredentials: true 
    })
  } 
}

import { AfterContentInit, Component, Input, ChangeDetectionStrategy, ViewEncapsulation, OnInit } from '@angular/core';
import { map, filter, take, switchMap } from 'rxjs/operators';

// import lottieAnimation from '../playground/data.json';
// import lottieAnimation2 from '../playground/data2.json';

// import { LazyLoadScriptService } from '../utilities/servicios/lazy-load-script.service';

// declare var $ : any;

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss']
})


export class PlaygroundComponent implements OnInit {
  // public lottieConfig: Object;
  // public lottieConfig2: Object;
  // private anim: any;
  // private anim2: any;
  // lottieAnimation = lottieAnimation;

  constructor(
    // private lazyLoadService: LazyLoadScriptService
    ) {
    // this.lottieConfig = {
    //   renderer: 'svg',
    //   loop: true,
    //   autoplay: true,
    //   animationData: lottieAnimation
    // };

    // this.lottieConfig2 = {
    //   renderer: 'svg',
    //   loop: true,
    //   autoplay: true,
    //   animationData: lottieAnimation2
    // };
  }

  ngOnInit() {

  }

  // ngAfterContentInit() {
  //   this.lazyLoadService.loadScript('https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js')
  //     .pipe(
  //       map(_ => 'jQuery is loaded'),
  //       filter(jquery => !!jquery),
  //       take(1),
  //       switchMap(_ => this.lazyLoadService.loadScript('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.9.0/slick.min.js')),
  //     ).subscribe(_ => {

  //     });
  // }

  // handleAnimation(anim: any) {
  //   this.anim = anim;
  //   anim.setSpeed(5)
  // }

  // handleAnimation2(anim: any) {
  //   this.anim2 = anim;
  //   anim.setSpeed(5)
  // }
}

import { Component, OnInit, Inject, ElementRef, PLATFORM_ID, ViewChildren, QueryList } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MenuService } from "../../utilities/servicios/menu.service";
import { ProductoService } from "../../utilities/servicios/producto.service";
import { Router } from '@angular/router';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(
    private menuService: MenuService,
    private router: Router,
    private ProductoService: ProductoService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  menus: any;
  isBrowser;
  nivel0: any;
  nivel1: any;
  nivel2: any;
  productos: any;
  photo: any;
  tituloFamilia: any;

  async ngOnInit() {
    this.getMenus();
    await this.getProductos();

    //Cerrar megamenu al hacer click fuera del area caliente (revisar)
    // $(".section_megamenu").click(function () {
    //   $('.nav-trigger').removeClass('nav-trigger--active');
    //   $('body').removeClass('nav-trigger--active');
    //   $('.header').removeClass('active');
    //   $('.section_megamenu').fadeOut(100)
    //   $("#openBuscador").fadeIn(100)
    // });
  }

  getMenus(): void {
    this.menuService.getMenus()
      .subscribe(data => {
        // Recuperamos los elementos del menu y los añadimos al array de su nivel
        // this.menus = data.menus.filter((items) => items.activo === true && items.titulo != 'Pacientes');
        this.menus = data.menus.filter((items) => items.activo === true);
        this.nivel0 = this.menus.filter((items) => items.lvl === 0);
        this.nivel1 = this.menus.filter((items) => items.lvl === 1);
        this.nivel2 = this.menus.filter((items) => items.lvl === 2);
      });
  }

  getProductos() {
    this.ProductoService.getProductos()
      .subscribe(data => {
        this.productos = data.productos.filter((items) => items.active === true);
      });
  }

  showMenu(id, event) {
    //Eliminamos la clase activo de los elementos del nivel 0 del menú
    $(".lvl0 a").removeClass("activeLvl");
    //Añadimos la clase activo al elemento que el usuario hace click
    if (event.target != undefined)
      $("#" + event.target.id).addClass("activeLvl");
    // Ocultamos los elementos del nivel 1 del menú
    $(".lvl1 a").hide();
    // Ocultamos los elementos del nivel 2 y 3 del menú
    $(".subniveles div").hide();
    // Ocultamos la información de la familia
    $('#familyInfo').hide();

    switch (id) {
      case "lv0_5ce67a980eb91d0ea8a61002":
        this.router.navigate(['/biblioteca']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break;
      case "lv0_5ceea377437d1b11c2db48a6":
        this.router.navigate(['/blog']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break;
      case "lv0_5cfa2fb6cfc5650506777d76":
        this.router.navigate(['/pacientes']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break;
      case "lv0_5d0755e2bbec9c0473ff956f":
        this.router.navigate(['/eventos']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break;
      case "lv0_5e44168ed461fe136174e67a":
        this.router.navigate(['/empresa']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break;
      case "lv0_5e451f7b6baf2d056c3541e9":
        this.router.navigate(['/ciencia']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break;
      default:
        $('.' + id).css('display', 'flex').siblings('div').hide();

        // Si el menú se encuentra en su versión mobile desplazamos el segundo nivel al centro de la pantalla
        if (window.innerWidth <= 767.98)
          $("#megamenu").css('transform', 'translateX(-100%)');
    }
  }

  previousLvl(lvl) {
    switch (lvl) {
      case 1:
        $("#megamenu").css('transform', 'translateX(0)');
        break;
      case 2:
        $("#megamenu").css('transform', 'translateX(-100%)');
        break;
      default:
        $("#megamenu").css('transform', 'translateX(0)');
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    // Si el menú se encuentra en su versión desktop desplazamos el primer nivel al centro de la pantalla
    if (window.innerWidth > 575.98)
      $("#megamenu").css('transform', 'translateX(0%)');
  }

  showSubMenu(id, event) {
    //Eliminamos la clase activo de los elementos del nivel 0 del menú
    $(".lvl1 a").removeClass("activeLvl");
    //Añadimos la clase activo al elemento que el usuario hace click
    if (event.target != undefined)
      $("#" + event.target.id).addClass("activeLvl");
    //Ocultamos los niveles 2 y 3
    $(".subniveles div").hide();

    // Ocultamos la información de la familia
    $('#familyInfo').hide();

    switch (id) {
      /* case "lv1_5ce67a980eb91d0ea8a61002":
        this.router.navigate(['/biblioteca']);
        $('.nav-trigger').click();
        $('.section_megamenu').fadeToggle(100);
        $("#openBuscador").fadeToggle(100)
        break; */
      default:
        //Mostramos los subniveles del elemento que el usuario hace click
        $('.' + id).css('display', 'flex');
        $('.' + id).children().css('display', 'flex')

        // Recuperamos la foto de la familia
        let str = event.target.id.replace('lv1_', '')
        let familia = this.menus.filter((items) => items._id === str);
        this.tituloFamilia = familia[0].titulo;
        this.photo = familia[0].photo[0].length > 0 && familia[0].photo[0] != "" ? "http://api.klockner.es" + familia[0].photo[0] : 'assets/images/productos/defaultBox.png';
        $("#familyIMG").css("background-image", "url(" + this.photo + ")")
        $('#familyInfo').css('display', 'flex');
    }

    // Si el menú se encuentra en su versión mobile desplazamos el tercer nivel al centro de la pantalla
    if (window.innerWidth <= 767.98)
      $("#megamenu").css('transform', 'translateX(-200%)');
  }

  navigateToRecipeDetails(url) {
    //Add dashes in place of spaces
    let nameInRoute: String = url.split(' ').join('-');
    this.router.navigate([nameInRoute]);

    $('.nav-trigger').click();
    $('.section_megamenu').fadeToggle(100);
  }

  // Mostrar el primer elemento del megamenu al cargar
  @ViewChildren('lvl0loop') lvl0loop: QueryList<any>;
  @ViewChildren('lvl1loop') lvl1loop: QueryList<any>;
  ngAfterViewInit() {
    if (window.innerWidth > 575.98) {
      let event = {
        target: {
          id: "",
        }
      };

      this.lvl0loop.changes.subscribe(data => {
        let firstElement = data._results[0].nativeElement.id;
        event.target.id = firstElement;
        this.showMenu(firstElement, event)
      })

      this.lvl1loop.changes.subscribe(data => {
        let firstElement = data._results[0].nativeElement.id;
        event.target.id = firstElement;
        this.showSubMenu(firstElement, event)
      })
    }
  }
}

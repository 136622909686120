import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Contacto } from '../clases/contacto';
import { CookieService } from 'ngx-cookie-service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class ContactoService {

  private contactosURL = 'https://api.klockner.es/contactos';  // URL to web api
  private contactoURL = 'https://api.klockner.es/contacto';  // URL to web api
  private ctaURL = 'https://api.klockner.es/cta';

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer '+this.cookieService.get("admin_token"),
    })
  };
    
  getContactos (): Observable<Contacto> {
    return this.http.get<any>(this.contactosURL, this.httpOptions)
  }

  createContactos(producto){
    return this.http.post(this.contactoURL, producto)
  }

  createCta(cta){
    return this.http.post(this.ctaURL, cta)
  }

}

import { Component, OnInit, ElementRef } from '@angular/core';
import { MenuService } from "../../utilities/servicios/menu.service";
import { ProductoService } from "../../utilities/servicios/producto.service";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-productos-soluciones',
  templateUrl: './productos-soluciones.component.html',
  styleUrls: ['./productos-soluciones.component.scss']
})
export class ProductosSolucionesComponent implements OnInit {

  constructor(
    private menuService: MenuService,
    private ProductoService: ProductoService,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.getMenu();

    //Check Session Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.getMenu();
    });
    //END Check Session Router Events
  }
  menus: any = [];
  productos: any = [];
  menu: any;
  familia: boolean = false;
  home: boolean;
  parent: any;
  preParent: any;

  ngOnInit() {

  }

  getMenu(): void {
    this.menus = [];
    this.productos = [];
    this.menu = null;
    this.parent = null;
    this.preParent = null;
    this.familia = false;

    let titulo = this.route.snapshot.paramMap.get('titulo');
    //remove the dashes and add spaces
    if (titulo) titulo = titulo.split('-').join(' ');
    
    this.menuService.getMenu(titulo)
      .subscribe(data => {
        this.menu = data.menu[0];
        if (this.menu == undefined) {
          this.home = true;
        } else {
          this.home = false;
          let id = this.menu.parent;
          this.menuService.getMenuById(id)
            .subscribe(data => {
              this.parent = data.menu;

              let id = data.menu.parent;
              this.menuService.getMenuById(id)
                .subscribe(data => {
                  this.preParent = data.menu;
                });
            });
        }

        this.menuService.getMenus()
          .subscribe(data => {
            let dataTitle = this.route.snapshot.data['title'];
            this.menus = [];

            if (dataTitle == "Home" || titulo == "Productos y soluciones") {
              data.menus.forEach(menu => {
                this.home = true;
                if (menu.lvl == 1 && menu.activo && menu.parent != '5e44168ed461fe136174e67a') {
                  this.menus.push(menu)
                  this.familia = true;
                }
              });
            } else {
              this.home = false;
              data.menus.forEach(menu => {
                if (menu.parent == this.menu._id && menu.activo) {
                  this.menus.push(menu)
                  this.familia = true;
                }
              });

              this.ProductoService.getProductos()
                .subscribe(data => {
                  this.productos = [];
                  data.productos.forEach(producto => {
                    if (producto.familia[0]._id == this.menu._id && producto.active) {
                      this.productos.push(producto)
                    }
                  });
                });
            }
          });
      });
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }

}

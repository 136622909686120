import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { EventoService  } from "../../../utilities/servicios/evento.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-evento',
  templateUrl: './listado-evento.component.html',
  styleUrls: ['./listado-evento.component.scss']
})
export class ListadoEventoComponent implements OnInit {

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['titulo','fecha', 'acciones','id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef
  
  constructor(
    private EventoService: EventoService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {
    this.getEventos();
  }
  

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getEventos(): void {
    this.EventoService.getEventos()
    .subscribe(data => {
      //PERMITIR FILTRAR COLUMNAS DEL POPULATE
      data.eventos.forEach(element => {
        element.fecha = this.datePipe.transform(element.fecha)
      });
      this.dataSource = new MatTableDataSource(data.eventos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  changeACTIVE(idEvento, activechange){
    this.EventoService.changeActive(idEvento, activechange)
    .subscribe(response => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
      location.reload();
    });
  }


  destacar(id, evento){
    this.EventoService.destacar(id, evento.checked).subscribe(data => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
    }, err => {
      this.openSnackBar("No se pueden destacar mas elementos", "Aceptar")
      evento.source.checked = false;
    })
  }
}

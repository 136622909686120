import { Component, OnInit } from '@angular/core';
import { BlogService } from '../../utilities/servicios/blog.service';
@Component({
  selector: 'app-partial-blog',
  templateUrl: './partial-blog.component.html',
  styleUrls: ['./partial-blog.component.scss']
})
export class PartialBlogComponent implements OnInit {
  noticias: any = [];
  constructor(
    private blogService: BlogService
  ) { }

  ngOnInit() {
    this.destacados();
  }

  destacados(): void {
    this.blogService.destacados()
      .subscribe(data => {
        this.noticias = data.blogs.filter((items) => items.activo === true);
      });
  }

}


import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class PonenteService {

  private ponentesURL = 'https://api.klockner.es/ponente/all';
  private ponenteURL = 'https://api.klockner.es/ponente';
  private ponenteNameURL = 'https://api.klockner.es/ponente/name';
  private changeactiveURL = 'https://api.klockner.es/ponente/active/';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    
  }

  getPonentes(): Observable<any> {
    return this.http.get<any>(this.ponentesURL)
  }

  getPonente(id: String): Observable<any> {
    const url = `${this.ponenteURL}/${id}`;
    return this.http.get<any>(url)
  }

  getPonenteByName(name: String): Observable<any> {
    const url = `${this.ponenteNameURL}/${name}`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => console.log(_))
      );
  }

  updatePonente(payload, id) {
    return this.http.put(this.ponenteURL + "/" + id, payload, this.httpOptions)
  }

  createPonente(payload) {
    return this.http.post(this.ponenteURL + "/", payload, this.httpOptions)
  }

  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }
}
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import textos_legales from '../../textos_legales.json';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  textos = textos_legales;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    ) { }

  ngOnInit() {
    if (this.router.url == '/politica-de-cookies')
      this.openDialog(this.textos.cookies.titulo, this.textos.cookies.texto)
  }

  openDialog(titulo, mensaje): void {
    const dialogRef = this.dialog.open(texto_legal_dialog, {
      width: '600px',
      data: { titulo: titulo, mensaje: mensaje }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

export interface DialogData {
  titulo: string;
  mensaje: string;
}
@Component({
  selector: 'textoLegalDialog',
  templateUrl: '../../textoLegalDialog.html',
})

export class texto_legal_dialog {

  constructor(
    public dialogRef: MatDialogRef<texto_legal_dialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  closeDialog() {
    this.dialogRef.close();
  }

}
import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Producto } from '../../../utilities/clases/producto';
import { ProductoService } from "../../../utilities/servicios/producto.service";
import { CasoClinicoService } from "../../../utilities/servicios/caso_clinico.service";
import { MenuService } from "../../../utilities/servicios/menu.service";
import { MatSnackBar } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

export interface DialogData {
  mensaje: string;
}

@Component({
  selector: 'app-edit-producto',
  templateUrl: './edit-producto.component.html',
  styleUrls: ['./edit-producto.component.scss']
})

export class EditProductoComponent implements OnInit {
  producto: Producto;
  ficha_tecnica;
  guia_rapida;
  familias: any = [];
  familiaSeleccionada;
  relateds;
  selectedFiles = [];
  selectedSlides = [];
  selectedLogo = [];
  selectedPDF: File;
  selectedGUIA: File;
  fotos;
  videos = [];
  div;
  sliderDIV;
  slides;
  logoDIV;
  logo;
  faqs: any;
  casos_clinicos;
  color1: any;

  active_guia_rapida: boolean;
  active_ficha_tecnica: boolean;

  // ANGULAR MATERIAL CHIPS
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;

    // Add our video
    if ((value || '').trim()) {
      if (this.videos.length >= 3) {
        this.openSnackBar('Solo puedes añadir 3 videos', 'Aceptar');
        return false;
      }
      this.videos.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(video: any): void {
    const index = this.videos.indexOf(video);

    if (index >= 0) {
      this.videos.splice(index, 1);
    }
  }

  // WYSIWYG
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Introducir texto aquí...',
    translate: 'yes',
    uploadUrl: 'https://api.klockner.es/assets/blog', // if needed
    customClasses: [ // optional
      {
        name: "Número destacado",
        class: "editorFeaturedNumber",
      },
      {
        name: 'Texto destacado',
        class: 'editorFeaturedText'
      },
    ]
  };

  productoForm = this.fb.group({
    idProducto: ['', Validators.required],
    nombre: ['', Validators.required],
    descripcion: ['', Validators.required],
    titular: ['', Validators.required],
    active_ficha_tecnica: [''],
    ficha_tecnica: [''],
    active_guia_rapida: [''],
    guia_rapida: [''],
    familia: [],
    html: ['', Validators.required],
    sliderTitulo: ['', Validators.required],
    sliderDescripcion: ['', Validators.required],
    sliderColor: [''],
  });

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private fb: FormBuilder,
    private productoService: ProductoService,
    private CasoClinicoService: CasoClinicoService,
    private MenuService: MenuService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private auth: AuthService,
    private elRef: ElementRef
  ) {

  }

  ngOnInit() {
    this.getProducto();
    this.getFamilias();
  }

  clickFunction(i) {
    let element: HTMLElement = document.getElementById('faq' + i) as HTMLElement;
    element.click();
  }

  newFAQ() {
    this.faqs.push({})
  }

  deleteFAQ(i) {
    this.faqs.splice(i, 1);
  }

  numero = 0;
  numeroForSlider = 0;
  numeroForLogo = 0;
  onFileChanged(event) {
    if (event.target.id == 'fileInputSlider') {
      if (this.numeroForSlider == 0) {
        this.numeroForSlider = this.slides.length;
      }
      for (let i = 0; i < event.target.files.length; i++) {
        this.selectedSlides[this.numeroForSlider] = event.target.files[i];
        this.sliderDIV.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 0px 15px;' id='div_photo_slider_" + this.numeroForSlider + "' class='card_separator mat-card ng-star-inserted'>" +
          "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
          "<i _ngcontent-c10 class='fas fa-times remove_related remove_fotos' id='borrarSlide_" + this.numeroForSlider + "'></i>" +
          "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[i]) + ")' readonly='readonly'></div>" +
          "</mat-card-content>" +
          "</mat-card>");
        this.elRef.nativeElement.querySelector('#borrarSlide_' + this.numeroForSlider).addEventListener('click', this.removeSlide.bind(this, this.numeroForSlider, false));
        this.numeroForSlider++;
      }
    }
    if (event.target.id == 'fileInputLogo') {
      let i = 0;
      let fotoEvento = event.target.files[0];
      this.selectedLogo[0] = fotoEvento;

      let logoDIV = this.elRef.nativeElement.querySelector('#logoFoto');
      logoDIV.innerHTML = '';

      logoDIV.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 0px 15px;' id='div_photo_logo_" + i + "' class='div_photo_product card_separator mat-card ng-star-inserted'>" +
        "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
        "<i _ngcontent-c10 class='fas fa-times remove_related' style='cursor : pointer;' id='borrar_" + i + "'></i>" +
        "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[0]) + ")' readonly='readonly'></div>" +
        "</mat-card-content>" +
        "</mat-card>");

      this.elRef.nativeElement.querySelector('#borrar_' + i).addEventListener('click', this.removeLogo.bind(this, i, false));
    }
    if (event.target.id == 'fileInput') {
      if (this.numero == 0) {
        this.numero = this.fotos.length;
      }

      // if(this.selectedFiles.length >= 2 || this.numero >= 2){
      //   this.openSnackBar('Un producto solo puede tener 2 imágenes', 'Aceptar');
      //   $("#fileInput").val('');
      //   return false;
      // }

      for (let i = 0; i < event.target.files.length; i++) {
        this.selectedFiles[this.numero] = event.target.files[i];
        this.div.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 style='margin: 0px 15px;' id='div_photo_product_" + this.numero + "' class='card_separator mat-card ng-star-inserted'>" +
          "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
          "<i _ngcontent-c10 class='fas fa-times remove_related remove_fotos' id='borrar_" + this.numero + "'></i>" +
          "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[i]) + ")' readonly='readonly'></div>" +
          "</mat-card-content>" +
          "</mat-card>");
        this.elRef.nativeElement.querySelector('#borrar_' + this.numero).addEventListener('click', this.removeImage.bind(this, this.numero, false));
        this.numero++;
      }
    }
  }

  ngAfterViewInit() {
    this.div = this.elRef.nativeElement.querySelector('#fotos');
    this.sliderDIV = this.elRef.nativeElement.querySelector('#sliderFotos');
    this.logoDIV = this.elRef.nativeElement.querySelector('#logoFoto');
  }

  removeImage(this, i, foto) {
    $('#div_photo_product_' + i).remove()
    if (foto) {
      this.fotos.splice(i, 1)
    }
    delete this.selectedFiles[i]
  }

  removeSlide(this, i, slide) {
    $('#div_photo_slider_' + i).remove()
    if (slide) {
      this.slides.splice(i, 1)
    }
    delete this.selectedSlides[i]
  }

  removeLogo(this, logo) {
    $('#div_photo_logo_0').remove()
    if (logo) {
      this.logo = "";
    }
    delete this.selectedLogo[0]
  }

  onPDFChanged(event) {
    this.selectedPDF = event.target.files[0];
  }

  onGuiaChanged(event) {
    this.selectedGUIA = event.target.files[0];
  }

  getProducto(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.productoService.getProducto(String(id))
      .subscribe(data => {
        this.producto = data.producto;
        this.ficha_tecnica = data.producto.ficha_tecnica;
        this.guia_rapida = data.producto.guia_rapida;
        this.relateds = data.producto.relacionados;
        this.casos_clinicos = data.producto.casos_clinicos;
        this.fotos = data.producto.photo;
        this.slides = data.producto.slider.photo;
        this.videos = data.producto.video;
        this.logo = data.producto.logo;
        this.faqs = data.producto.faqs;
        this.productoForm.controls['idProducto'].setValue(data.producto._id);
        this.productoForm.controls['nombre'].setValue(data.producto.nombre);
        this.productoForm.controls['descripcion'].setValue(data.producto.descripcion);
        this.productoForm.controls['titular'].setValue(data.producto.titular);
        this.productoForm.controls['familia'].setValue(data.producto.familia[0]._id);
        this.productoForm.controls['html'].setValue(data.producto.html);
        this.productoForm.controls['sliderDescripcion'].setValue(data.producto.slider.descripcion);
        this.productoForm.controls['sliderTitulo'].setValue(data.producto.slider.titulo);
        this.productoForm.controls['sliderColor'].setValue(data.producto.slider.color);

        this.productoForm.controls['active_ficha_tecnica'].setValue(data.producto.active_ficha_tecnica.toString());
        console.log(data.producto.active_ficha_tecnica);
        this.active_ficha_tecnica = data.producto.active_ficha_tecnica;
        this.productoForm.controls['active_guia_rapida'].setValue(data.producto.active_guia_rapida.toString());
        this.active_guia_rapida = data.producto.active_guia_rapida;
      });
  }

  getFamilias() {
    this.MenuService.getMenus()
      .subscribe(data => {
        data.menus.forEach(familia => {
          if (familia.titulo != 'Ciencia' && familia.titulo != 'Empresa' && familia.titulo != 'Blog' && familia.titulo != 'Pacientes' && familia.titulo != 'Formación y Eventos' && familia.titulo != 'Biblioteca') {
            if (familia.lvl == 1 || familia.lvl == 2 || familia.lvl == 3) {
              this.MenuService.getMenuById(familia.parent)
                .subscribe(parent => {
                  familia.parentName = parent.menu.titulo;
                  if (parent.menu.lvl > 1) {
                    this.MenuService.getMenuById(parent.menu.parent)
                      .subscribe(preParent => {
                        familia.preParentName = preParent.menu.titulo;
                      });
                  }
                });
              this.familias.push(familia)
            }
          }
        });
      });
  }

  onSubmit() {
    this.faqs = [];
    for (let i = 0; i < $(".faq").length; i++) {
      let faq = {
        question: $("#faq_question" + i).val(),
        answer: $("#faq_answer" + i).val(),
      }
      this.faqs.push(faq)
    }

    var input = new FormData();
    input.append('producto', JSON.stringify(this.productoForm.value));
    input.append('faqs', JSON.stringify(this.faqs));
    input.append('videos', JSON.stringify(this.videos));

    var auxRelateds = [];
    this.relateds.forEach(related => {
      auxRelateds.push(related._id)
    });
    input.append('relateds', JSON.stringify(auxRelateds));

    var auxCasosClinicos = [];
    this.casos_clinicos.forEach(caso_clinico => {
      auxCasosClinicos.push(caso_clinico._id)
    });
    input.append('casos_clinicos', JSON.stringify(auxCasosClinicos));

    if (this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        input.append('image[]', file);
      });
      let count = $('.fotos mat-card').length;
      input.append('length', JSON.stringify(count));
    }

    if (this.selectedLogo.length > 0) {
      this.selectedLogo.forEach(file => {
        input.append('logo', file);
      });
      input.append('length', JSON.stringify(0));
    }

    if (this.selectedSlides.length > 0) {
      this.selectedSlides.forEach(file => {
        input.append('slider[]', file);
      });
      let count = $('.sliderFotos mat-card').length;
      input.append('slidefLength', JSON.stringify(count));
    }

    input.append('logo', JSON.stringify(this.logo));
    input.append('slides', JSON.stringify(this.slides));
    input.append('fotos', JSON.stringify(this.fotos));

    if (this.selectedPDF) {
      input.append('pdf', this.selectedPDF, this.selectedPDF.name);
    }

    if (this.selectedGUIA) {
      input.append('guia', this.selectedGUIA, this.selectedGUIA.name);
    }

    input.append('oldGuia', JSON.stringify(this.guia_rapida));
    input.append('oldPDF', JSON.stringify(this.ficha_tecnica));

    this.productoService.updateProducto(input, this.productoForm.value.idProducto).subscribe(response => {
      this.openSnackBar('Producto modificado correctamente', 'Aceptar');
    }, err => {
      this.openSnackBar('Ha ocurrido un error modificando el producto', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  get idProducto() { return this.productoForm.get('idProducto'); }
  get nombreProducto() { return this.productoForm.get('nombreProducto'); }
  get precioProducto() { return this.productoForm.get('precioProducto'); }
  get photoProducto() { return this.productoForm.get('photoProducto'); }

  removeRelated(related_id) {
    if (this.relateds.find(x => x._id == related_id)) {
      this.relateds.splice(this.relateds.findIndex(x => x._id == related_id), 1);
    }
  }

  removeCasoClinico(caso_clinico_id) {
    if (this.casos_clinicos.find(x => x._id == caso_clinico_id)) {
      this.casos_clinicos.splice(this.casos_clinicos.findIndex(x => x._id == caso_clinico_id), 1);
    }
  }

  addRelated() {
    var exceptions = [];
    exceptions.push(this.producto._id)
    this.relateds.forEach(related => {
      exceptions.push(related._id)
    });

    this.productoService.getExceptions(exceptions)
      .subscribe(data => {
        if (data.productos == "") {
          this.openSnackBar('No hay productos relacionados disponibles', 'Aceptar');
        } else {
          this.openDialog(exceptions);
        }
      });
  }

  addCasoClinico() {
    var casos_clinicos_exceptions = [];
    this.casos_clinicos.forEach(caso_clinico => {
      casos_clinicos_exceptions.push(caso_clinico._id)
    });

    this.CasoClinicoService.getExceptions(casos_clinicos_exceptions)
      .subscribe(data => {
        if (data.casos_clinicos == "") {
          this.openSnackBar('No hay casos clínicos disponibles', 'Aceptar');
        } else {
          this.openCasoClinicoDialog(casos_clinicos_exceptions);
        }
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  getRelatedProduct(relatedID) {
    this.productoService.getProducto(String(relatedID))
      .subscribe(data => {
        this.relateds.push(data.producto)
      });
  }

  getCasoClinico(caso_clinico_id) {
    this.CasoClinicoService.getCasoClinico(String(caso_clinico_id))
      .subscribe(data => {
        this.casos_clinicos.push(data.caso_clinico)
      });
  }

  openDialog(exceptions): void {
    const dialogRef = this.dialog.open(addRelatedDialog, {
      width: '500px',
      data: exceptions
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getRelatedProduct(result);
    });
  }

  openCasoClinicoDialog(exceptions): void {
    const dialogRef = this.dialog.open(addCasoClinicoDialog, {
      width: '500px',
      data: exceptions
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getCasoClinico(result);
    });
  }

  active_ficha_tecnicaChange(event) {
    switch (event.value) {
      case 'true':
        this.active_ficha_tecnica = true;
        break;
      case 'false':
        this.active_ficha_tecnica = false;
        break;
      default:
        this.active_ficha_tecnica = false;
        break;
    }
  }

  active_guia_rapidaChange(event) {
    switch (event.value) {
      case 'true':
        this.active_guia_rapida = true;
        break;
      case 'false':
        this.active_guia_rapida = false;
        break;
      default:
        this.active_guia_rapida = false;
        break;
    }
  }
}

export interface RelatedList {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'addRelatedDialog',
  templateUrl: '../addRelatedDialog.html',
})
export class addRelatedDialog {

  allRelateds;
  selectedProduct: string;

  constructor(
    public dialogRef: MatDialogRef<addRelatedDialog>,
    private productoService: ProductoService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.productoService.getExceptions(data)
      .subscribe(data => {
        this.allRelateds = data.productos;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addRelated() {
    if (this.selectedProduct != undefined) {
      this.closeDialog();
    } else {
      this.openSnackBar('Debes seleccionar un producto', 'Aceptar');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  closeDialog() {
    this.dialogRef.close(this.selectedProduct);
  }

}

// CASO CLINICO DIALOG

export interface RelatedList {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'addCasoClinicoDialog',
  templateUrl: '../addCasoClinicoDialog.html',
})
export class addCasoClinicoDialog {

  allCasosClinicos;
  selectedCasoClinico: string;

  constructor(
    public dialogRef: MatDialogRef<addRelatedDialog>,
    private CasoClinicoService: CasoClinicoService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.CasoClinicoService.getExceptions(data)
      .subscribe(data => {
        this.allCasosClinicos = data.casos_clinicos;
      });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addCasoClinico() {
    if (this.selectedCasoClinico != undefined) {
      this.closeDialog();
    } else {
      this.openSnackBar('Debes seleccionar un caso clínico', 'Aceptar');
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  closeDialog() {
    this.dialogRef.close(this.selectedCasoClinico);
  }

}
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PaginasService } from "../utilities/servicios/paginas.service";
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-paginas',
  templateUrl: './paginas.component.html',
  styleUrls: ['./paginas.component.scss'],
  // Aplica los estilos solo a este componente, no tienen repercusión con el resto de la web pero no se pueden recuperar los scripts desde fuera
  // encapsulation: ViewEncapsulation.ShadowDom,
  // template: `<dynamic-html [content]="html | safe"></dynamic-html>`
  // template: `<div [innerHtml]="html"></div>`
})

export class PaginasComponent implements OnInit {
  html;
  loadAPI: Promise<any>;

  constructor(
    private PaginasService: PaginasService,
    private route: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
  ) {
    this.getPaginabyURL();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });
  }

  getHtml(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public loadScript() {
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
        isFound = true;
      }
    }

    if (!isFound) {
      var dynamicScripts = ["https://widgets.skyscanner.net/widget-server/js/loader.js"];

      for (var i = 0; i < dynamicScripts.length; i++) {
        let node = document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);
      }

    }
  }

  getPaginabyURL() {
    let url = this.route.snapshot.paramMap.get('url');
    let url2 = this.route.snapshot.paramMap.get('url2');
    let url3 = this.route.snapshot.paramMap.get('url3');

    if (url2)
      url = url + "?" + url2

    if (url3)
      url = url + "?" + url3

    this.PaginasService.getPaginabyURL(String(url))
      .subscribe(data => {
        if (data.pagina.length > 0 && data.pagina[0].activo) {
          // this.pagina = data.pagina[0];
          this.html = this.sanitizer.bypassSecurityTrustHtml(data.pagina[0].html);
        }
        else
          this.router.navigate(['/404'])
      }, err => {
        this.router.navigate(['/404'])
      });
  }
}

import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { FaqsService } from "../../../utilities/servicios/faqs.service";

import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";

import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-new-faq',
  templateUrl: './new-faq.component.html',
  styleUrls: ['./new-faq.component.scss']
})

export class NewFaqComponent implements OnInit {
  isBrowser;
  faq: any;

  // WYSIWYG
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '25rem',
    minHeight: '5rem',
    placeholder: 'Introducir texto aquí...',
    translate: 'yes',
    uploadUrl: 'https://api.klockner.es/assets/faq', // if needed
    customClasses: [ // optional
      {
        name: "Número destacado",
        class: "editorFeaturedNumber",
      },
      {
        name: 'Texto destacado',
        class: 'editorFeaturedText'
      },
    ]
  };

  faqForm = this.fb.group({
    question: ['', Validators.required],
    answer: ['', Validators.required],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private faqsService: FaqsService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {

  }

  onSubmit() {
    this.faqsService.createFaq(this.faqForm.value).subscribe(response => {
      this.openSnackBar('FAQ creada correctamente', 'Aceptar');
      this.router.navigate(['admin/faq/editor']);
    }, err => {
      this.openSnackBar('Ha ocurrido un error modificando la FAQ', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}


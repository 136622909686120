import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menusURL = 'https://api.klockner.es/menu/all';
  private menuURL = 'https://api.klockner.es/menu';
  private menuUrlID = 'https://api.klockner.es/menu/idNormal';
  private searchMenusURL = 'https://api.klockner.es/menu/search/';
  private changeactiveURL = 'https://api.klockner.es/menu/active/';


  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  //Crear articulo Menu.
  createMenu(payload) {
    return this.http.post(this.menuURL, payload, this.httpOptions)
      .pipe(
        tap(_ => {
          // console.log(_);
        })
      );
  }

  //Actualizar articulo Menu.
  updateMenu(payload, id) {
    return this.http.put(this.menuURL + "/" + id, payload, this.httpOptions)
  }

  //Recupera todos los articulos del Menu.
  getMenus() {
    return this.http.get<any>(this.menusURL)
  }

  //Recupera un articulo de Menu por su nombre.
  getMenu(nombre: String): Observable<any> {
    const url = `${this.menuURL}/${nombre}`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => {
          // console.log(_)
        })
      );
  }

  //Recupera un articulo de Menu por su id.
  getMenuById(id: String): Observable<any> {
    const url = `${this.menuUrlID}/${id}`;
    return this.http.get<any>(url)
  }

  searchMenus(term) {
    return this.http.get<any>(this.searchMenusURL + term)
  }

  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PaginasService {

  private paginasURL = 'https://api.klockner.es/pagina/all';
  private paginaURL = 'https://api.klockner.es/pagina';
  private paginaURLID = 'https://api.klockner.es/pagina/id';
  private changeactiveURL = 'https://api.klockner.es/pagina/active/';
  private widgetsURL = "https://api.klockner.es/widget/all";


  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  //Crear página.
  createPagina(payload) {
    return this.http.post(this.paginaURL, payload, this.httpOptions)
      .pipe(
        tap(_ => {
          // console.log(_);
        })
      );
  }

  //Actualizar página.
  updatePagina(payload, id) {
    return this.http.put(this.paginaURL + "/" + id, payload, this.httpOptions)
      .pipe(
        tap(_ => {
          // console.log(_);
        })
      );
  }

  //Recupera todas las páginas.
  getPaginas() {
    return this.http.get<any>(this.paginasURL)
      .pipe(
        tap(_ => {
          // console.log(_);
        })
      );
  }

  //Recupera una página por su nombre.
  getPagina(nombre: String): Observable<any> {
    const url = `${this.paginaURLID}/${nombre}`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => {
          // console.log(_)
        })
      );
  }

  //Recupera una página por su nombre.
  getPaginabyURL(pageURL: String): Observable<any> {
    const url = `${this.paginaURL}/${pageURL}`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => {
          // console.log(_)
        })
      );
  }

  //Cambia el estado de una página activo/inactivo.
  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
      .pipe(
        tap(_ => {
          // console.log(_)
        })
      );
  }

  getWidgets() {
    return this.http.get<any>(this.widgetsURL)
      .pipe(
        tap(_ => {
          // console.log(_);
        })
      );
  }
}

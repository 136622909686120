import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { EventoService } from "../../../utilities/servicios/evento.service";
import { PonenteService } from "../../../utilities/servicios/ponente.service";

import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";

import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-new-evento',
  templateUrl: './new-evento.component.html',
  styleUrls: ['./new-evento.component.scss']
})

export class NewEventoComponent implements OnInit {
  evento: any;
  selectedFiles = [];
  ponentes = [];
  selectedPonentes = [];
  isBrowser;
  selectedPDF: File;
  presentaciones: any = [];
  numero = 0;

  //Para poner la información en la dataTable
  eventoForm = this.fb.group({
    titulo: ['', Validators.required],
    descripcion: ['', Validators.required],
    fecha: ['', Validators.required],
    codigo_postal: ['', Validators.required],
    poblacion: ['', Validators.required],
    pais: ['', Validators.required],
    provincia: ['', Validators.required],
    direccion: ['', Validators.required],
    programa: [''],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private eventoService: EventoService,
    private ponenteService: PonenteService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private elRef: ElementRef,
    private router:Router,
    private datePipe: DatePipe,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  clickFunction(i) {
    let element: HTMLElement = document.getElementById('presentacion' + i) as HTMLElement;
    element.click();
  }

  newPresentacion(){
    this.presentaciones.push({})
  }

  deletePresentacion(i){
    this.presentaciones.splice(i, 1);
  }

  onFileChanged(event, i) {
    if (this.isBrowser) {
      let fotoEvento = event.target.files[0];
      this.selectedFiles[i] = fotoEvento;

      let div = this.elRef.nativeElement.querySelector('#fotos' + i);
      div.innerHTML = '';

      div.insertAdjacentHTML('beforeend', "<mat-card _ngcontent-c10 id='div_photo_product_" + i + "' class='div_photo_product card_separator mat-card ng-star-inserted'>" +
        "<mat-card-content _ngcontent-c10 class='mat-card-content' style='text-align: right;'>" +
        "<i _ngcontent-c10 class='fas fa-times remove_related' style='cursor : pointer;' id='borrar_" + i + "'></i>" +
        "<div _ngcontent-c10 class='post_image_especial' style='background-image: url(" + URL.createObjectURL(event.target.files[0]) + ")' readonly='readonly'></div>" +
        "</mat-card-content>" +
        "</mat-card>");

      this.elRef.nativeElement.querySelector('#borrar_' + i).addEventListener('click', this.removeImage.bind(this, i, false));
    }
  }

  removeImage(this, i, foto) {
    if (this.isBrowser) {
      $('#div_photo_product_' + i).remove();
      $('#presentacion' + i).val('');
      delete this.selectedFiles[i]
    }
  }

  onPDFChanged(event) {
    this.selectedPDF = event.target.files[0];
  }

  ngOnInit() {
    this.getPonentes();
  }

  getPonentes() {
    this.ponenteService.getPonentes()
      .subscribe(data => {
        this.ponentes = data.ponentes;
      });
  }

  changePonente(event, i){
    this.selectedPonentes[i] = event.value;
  }

  onSubmit() {
    this.presentaciones = [];
    for (let i = 0; i < $(".presentacion").length; i++) {
      let nombreImagen : any = '';
      if (this.selectedFiles.length > 0) {
        if(this.selectedFiles[i] != undefined){
          nombreImagen = this.selectedFiles[i].name;
        }
      }

      let ponentesSeleccionados : any = [];
      if (this.selectedPonentes.length > 0) {
        if(this.selectedPonentes[i] != undefined){
          ponentesSeleccionados = this.selectedPonentes[i];
        }
      }

      let presentacion = {
        titulo: $("#presentacion_titulo"+i).val(),
        descripcion: $("#presentacion_descripcion"+i).val(),
        horario: $("#presentacion_horario"+i).val(),
        photo: nombreImagen,
        ponente: ponentesSeleccionados
      }
      if(nombreImagen == ''){
        presentacion.photo = $("#photoName"+i).val()
      }
      this.presentaciones.push(presentacion)
    }

    var input = new FormData();
    input.append('evento', JSON.stringify(this.eventoForm.value));
    input.append('presentaciones', JSON.stringify(this.presentaciones));
    input.append('fotos', JSON.stringify(this.selectedFiles));

    if (this.selectedFiles.length > 0) {
      this.selectedFiles.forEach(file => {
        input.append('image[]', file);
      });
    }
    if(this.selectedPDF){
      input.append('pdf', this.selectedPDF, this.selectedPDF.name);
    }

    this.eventoService.createEvento(input).subscribe(response => {
      this.openSnackBar('Evento creado correctamente', 'Aceptar');
      this.router.navigate(['admin/evento/editor']);
    }, err => {
      this.openSnackBar('Ha ocurrido un error creando el evento', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}


import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { GlobalUtilities } from '../../utilities/clases/global_utilities';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isBrowser;
  currentUrl;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private utilities: GlobalUtilities,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    public dialog: MatDialog,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      if (this.router.url.indexOf('/pacientes') > -1) {
        $(".menu").hide();
        this.currentUrl = 'pacientes';
      } else {
        $(".menu").show();
      }

      if (this.cookieService.get("profesional") != "true" && (window.location.href.toString().split(window.location.host)[1] != '/pacientes' && window.location.href.indexOf("admin") > -1 == false))
        this.openDialog();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    $('#iconMenu').click(function () {
      event.preventDefault();
      $('.nav-trigger').toggleClass('nav-trigger--active');
      $('body').toggleClass('nav-trigger--active');
      $('.header').toggleClass('active');
    });

    $(".iconsContainer").not("#openBuscador").click(function (event) {
      $(".buscadorMobile").slideUp("fast", function () { });

      if ($(this).attr('id') != "iconMenu") {
        $('.nav-trigger').removeClass('nav-trigger--active');
        $('body').removeClass('nav-trigger--active');
        $('.header').removeClass('active');
        $('.section_megamenu').fadeOut(100);
      }
    });

    if (this.router.url.indexOf('/pacientes') > -1) {
      $(".menu").hide();
      this.currentUrl = 'pacientes';
    } else {
      $(".menu").show();
    }
  }

  ngAfterViewInit(){
    $('#logoHeader').click(function () {
      if ($(".header").hasClass('active')) {
        $('.nav-trigger').toggleClass('nav-trigger--active');
        $('body').toggleClass('nav-trigger--active');
        $('.header').toggleClass('active');
        $('.section_megamenu').fadeOut(100);
      }
    });
  }

  openSocial(event) {
    if (event == 'open') {
      if ($(".iconOpened").length <= 0) {
        $("#openSocial").addClass('iconOpened');
        $(".social").addClass('socialOpened');
        this.utilities.animateCSS('.social', 'fadeInRight', function () {
          $(".menu").css("width", "50%");
        })
      }
    } else {
      if($(".social").css('width') == '140px'){
        $(".social").removeClass('socialOpened');
        $(".socialElement").hide();
        $("#openSocial").removeClass('iconOpened');
        this.utilities.animateCSS('.social', 'fadeOutRight', function () {
          $(".menu").css("width", "80%");
          $(".socialElement").show();
        })
      }
    }
  }

  openBuscador(event) {
    if (this.isBrowser) {
      if (event == 'open' && !$(".buscadorMobile").hasClass("opened")) {
        $(".buscadorMobile").slideDown("fast", function () { });
      } else {
        $(".buscadorMobile").slideUp("fast", function () { });
      }
    }
  }

  showMenu() {
    $('.section_megamenu').fadeToggle(100)
    $("#openBuscador").fadeToggle(100)
    $("#megamenu").css('transform', 'translateX(0%)');
  }

  buscar() {
    var term = $("#term").val();
    if (term == "")
      return this.openSnackBar('¡Ups! Debes escribir qué quieres buscar.', 'Aceptar');

    this.openBuscador('close');
    this.router.navigate(['resultados/' + term]);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(advertenciaDialog, {
      width: '400',
      hasBackdrop: true,
      backdropClass: 'advertenciaBackdrop',
      panelClass: 'advertenciaPanel'
    });

    $("body").css('overflow', 'hidden');

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      $("body").css('overflow', 'auto');
    });
  }
}

@Component({
  selector: 'advertenciaDialog',
  templateUrl: '../../advertenciaDialog.html',
  styleUrls: ['../../advertenciaDialog.scss']
})

export class advertenciaDialog {

  constructor(
    public dialogRef: MatDialogRef<advertenciaDialog>,
    private cookieService: CookieService,
  ) {
    dialogRef.disableClose = true;
  }

  async reject() {
    await this.dialogRef.close();
    window.location.href = "/pacientes"
  }

  closeDialog() {
    this.cookieService.set('profesional', 'true');
    this.dialogRef.close();
    window.location.reload(true);
  }
}

import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

import { PaginasService } from "../../../utilities/servicios/paginas.service";

import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";

import grapesjs from 'grapesjs';
import grapesjs_lory_slider from 'grapesjs-lory-slider';
import grapesjs_blocks_basic from 'grapesjs-blocks-basic';

@Component({
  selector: 'app-new-paginas',
  templateUrl: './new-paginas.component.html',
  styleUrls: ['./new-paginas.component.scss']
})
export class NewPaginasComponent implements OnInit {
  pagina;
  widgets;
  editor;
  isBrowser;
  //Para poner la información en la dataTable
  paginaForm = this.fb.group({
    nombre: ['', Validators.required],
    url: ['', Validators.required],
    html: [''],
    css: [''],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private paginasService: PaginasService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.grapesJS();
  }

  onSubmit() {
    // Recuperar del navegador el HTML y el CSS de GrapesJS.
    let html = localStorage.getItem('gjs-html');
    let css = localStorage.getItem('gjs-css');

    // Insertar valores de HTML y CSS a los campos del formulario.
    this.paginaForm.controls['html'].setValue(html);
    this.paginaForm.controls['css'].setValue(css);

    this.paginasService.createPagina(this.paginaForm.value).subscribe(response => {
      this.openSnackBar('Página modificada correctamente', 'Aceptar');
    }, err => {
      this.openSnackBar('Ha ocurrido un error modificando la página', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  grapesJS() {
    // Configuración GrapesJS
    this.editor = grapesjs.init({
      // Selector of the editor
      container: '#gjs',
      // Get the content for the canvas directly from the element
      fromElement: true,
      // Size of the editor
      height: '500px',
      width: 'auto',
      // plugins
      plugins: [
        grapesjs_lory_slider,
        grapesjs_blocks_basic
      ],
      // Recuperar imagenes
      assetManager: {
        upload: 'https://api.klockner.es/assets/widgets',
        assets: [
          'https://api.klockner.es/assets/widgets',
        ],
      },
    });

    // Recuperar los widgets y añadir a GrapesJS
    this.paginasService.getWidgets()
      .subscribe(data => {
        this.widgets = data.widgets;
        this.widgets.forEach(widget => {
          this.editor.BlockManager.add(widget.nombre, {
            // label: '<b>' + widget.nombre + '</b> <img src="https://api.klockner.es' + widget.icono + '">',
            label: '<img style="width: 100%;" src="https://api.klockner.es' + widget.icono + '">',
            content: widget.html,
            category: 'Widgets',
          })
        });
      });
  }
}
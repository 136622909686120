import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { FaqsService } from "../../../utilities/servicios/faqs.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-faqs',
  templateUrl: './listado-faqs.component.html',
  styleUrls: ['./listado-faqs.component.scss']
})
export class ListadoFaqsComponent implements OnInit {
  faqs: any = [];

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['question', 'acciones', 'id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  constructor(
    private faqsService: FaqsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {
    this.getFaqs();
  }

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getFaqs(): void {
    this.faqsService.getFaqs()
      .subscribe(data => {
        this.faqs = data.faqs;
        this.dataSource = new MatTableDataSource(data.faqs);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  changeACTIVE(idFAQ, activechange) {
    this.faqsService.changeActive(idFAQ, activechange)
      .subscribe(response => {
        this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
        location.reload();
      });
  }
}

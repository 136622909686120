import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgModule, ModuleWithProviders } from '@angular/core';

@NgModule({
    imports: [
        CommonModule,
        //Usar versión ^4.0.3 para SSR
        ColorPickerModule
    ],
    exports: [
        CommonModule,
        //Usar versión ^4.0.3 para SSR
        ColorPickerModule
    ],
    declarations: [],
    providers: []
})
export class SharedModule {

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule
        };
    }
}
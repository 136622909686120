import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.scss']
})
export class EmpresaComponent implements OnInit {
  slidesSobreNosotros = [
    "/assets/images/empresa/sobreNosotros1.jpg",
    "/assets/images/empresa/sobreNosotros2.jpg",
  ]

  slidesFamilia = [
    "/assets/images/empresa/familia1.jpg",
    "/assets/images/empresa/familia2.jpg",
  ]

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
    arrows: false,
  };

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { EventoService } from "../utilities/servicios/evento.service";
import { BlogService } from '../utilities/servicios/blog.service';
import { MenuService } from "../utilities/servicios/menu.service";
import { ProductoService } from "../utilities/servicios/producto.service";
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-resultados-buscador',
  templateUrl: './resultados-buscador.component.html',
  styleUrls: ['./resultados-buscador.component.scss']
})
export class ResultadosBuscadorComponent implements OnInit {
  eventos: any;
  menus: any;
  productos: any;
  blogs: any;

  constructor(
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private EventoService: EventoService,
    private BlogService: BlogService,
    private MenuService: MenuService,
    private ProductoService: ProductoService,
  ) {
    //Check Session Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.eventos = [];
      this.menus = [];
      this.productos = [];
      this.blogs = [];
      this.searchResults()
    });
    //END Check Session Router Events
  }

  ngOnInit() {
    this.searchResults();
  }

  searchResults() {
    let term = this.route.snapshot.paramMap.get('term');

    //OBTENER INFO EVENTOS, BLOG, FAMILIAS Y PRODUCTOS
    this.EventoService.searchEventos(term)
      .subscribe(data => {
        this.eventos = data.eventos.filter((items) => items.activo === true);
      });

    this.BlogService.searchBlogs(term)
      .subscribe(data => {
        this.blogs = data.filter((items) => items.activo === true);
      });


    this.MenuService.searchMenus(term)
      .subscribe(data => {
        this.menus = data.menus.filter((items) => items.activo === true);
      });


    this.ProductoService.searchProductos(term)
      .subscribe(data => {
        this.productos = data.productos.filter((items) => items.active === true);
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

}

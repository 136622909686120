
import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class DescargaService {

  private descargasURL = 'https://api.klockner.es/descarga/all';
  private descargaURL = 'https://api.klockner.es/descarga';
  private descargaNameURL = 'https://api.klockner.es/descarga/name';
  private changeactiveURL = 'https://api.klockner.es/descarga/active/';
  private filterDescargasURL = 'https://api.klockner.es/descarga/search/filter/';
  private searchDescargasURL = 'https://api.klockner.es/descarga/search/';
  private destacarURL = 'https://api.klockner.es/descarga/destacar/';
  private destacadosURL = 'https://api.klockner.es/descarga/destacados/all';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  getDescargas(): Observable<any> {
    return this.http.get<any>(this.descargasURL)
  }

  getDescarga(id: String): Observable<any> {
    const url = `${this.descargaURL}/${id}`;
    return this.http.get<any>(url)
  }

  getDescargaByName(name: String): Observable<any> {
    const url = `${this.descargaNameURL}/${name}`;
    return this.http.get<any>(url)
      .pipe(
        tap(_ => console.log(_))
      );
  }

  updateDescarga(payload, id) {
    return this.http.put(this.descargaURL + "/" + id, payload, this.httpOptions)
  }

  createDescarga(payload) {
    return this.http.post(this.descargaURL + "/", payload, this.httpOptions)
  }

  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }

  searchEventos(term) {
    return this.http.get<any>(this.searchDescargasURL + term)
  }

  filterDescargas(payload) {
    return this.http.get<any>(this.filterDescargasURL + payload)
  }

  //Recupera los articulos del blog destacados.
  getDestacados() {
    return this.http.get<any>(this.destacadosURL, this.httpOptions)
  }

  //Cambia el estado de un articulo destacado/no destacado.
  destacar(idProduct, destacar) {
    var payload = { destacar: destacar }
    return this.http.put(this.destacarURL + idProduct, payload, this.httpOptions)
  }
}
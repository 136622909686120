import { Component, OnInit } from '@angular/core';
import { BlogService } from '../utilities/servicios/blog.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  noticias: any;
  destacados: any;
  noticiasDestacadas: any = [];
  response: any;
  tags: any;
  id_template = 1;
  p: any;
  loged: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private blogService: BlogService,
  ) { }

  ngOnInit() {
    this.getBlogs();
    this.getDestacados();
  }

  ngAfterViewInit(){

  }

  getBlogs(): void {
    this.blogService.getBlogs()
      .subscribe(data => {
        this.noticias = data.blogs.filter((items) => items.activo === true);
      });
  }

  getDestacados(): void {
    this.blogService.destacados()
      .subscribe(data => {
        this.destacados = data.blogs.filter((items) => items.activo === true);
      });
  }
}

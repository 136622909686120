import {Component, OnInit, ViewChild, Inject, ElementRef} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { DatePipe } from '@angular/common';
import { AuthService } from "../../utilities/servicios/auth.service";
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Contacto } from '../../utilities/clases/contacto';
import { ContactoService  } from "../../utilities/servicios/contacto.service";

import * as XLSX from 'xlsx';


export interface DialogData {
  mensaje: string;
}

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})

export class AdminContactoComponent implements OnInit {

  displayedColumns: string[] = ['nombreyapellidos', 'email', 'telefono', 'fecha', 'mensaje', 'acciones'];
  dataSource: MatTableDataSource<Contacto>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  contactos: any = <any>{};
  mensaje: any;

  constructor(
    private http: HttpClient,
    private contactoService: ContactoService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private auth: AuthService
  ) {
    this.getContactos();
  }

  ngOnInit() {

  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getContactos(): void {
    this.contactoService.getContactos()
    .subscribe(data => {
      
      //PERMITIR FILTRAR COLUMNAS DEL POPULATE
      data.contactos.forEach(element => {
        element.fecha = this.datePipe.transform(element.fecha)
      });

      this.contactos = data.contactos
      this.dataSource = new MatTableDataSource(data.contactos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, err =>{
      if(err.status == 401){
        this.auth.logout(true);
      }
    });
  }

  ExportTOExcel(){
    var jsonFormateado = this.PedidosJSONFormatter([]);
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(jsonFormateado);
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'listado_contacto');
    
    /* save to file */
    XLSX.writeFile(wb, 'listado_contacto.xlsx');
  }  

  PedidosJSONFormatter(payload){
    this.contactos.forEach(contacto => {
      var obj = {
        Nombre: contacto.nombre,
        Apellidos: contacto.apellidos,
        Email: contacto.email,
        Telefono: contacto.telefono,
        Mensaje: contacto.mensaje,
        Departamento: contacto.departamento,
        Fecha: this.datePipe.transform(contacto.fecha),
        Newsletter: contacto.newsletter,
        Politica: contacto.politica
      };
      payload.push(obj)
    });
    return payload;
  }

  openDialog(mensaje): void {
    this.mensaje = mensaje;
    
    const dialogRef = this.dialog.open(fullMessageDialog, {
      width: '500px',
      data: {mensaje: this.mensaje}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}

@Component({
  selector: 'fullMessageDialog',
  templateUrl: 'fullMessageDialog.html',
})
export class fullMessageDialog {

  constructor(
    public dialogRef: MatDialogRef<fullMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../utilities/servicios/auth.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-Adminlogin',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class AdminLoginComponent implements OnInit {

  isBrowser;
  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  constructor(@Inject(PLATFORM_ID) private platformId, private fb: FormBuilder, private auth:AuthService, private snackBar: MatSnackBar) {
    this.isBrowser = isPlatformBrowser(platformId);
   }

  ngOnInit() {
    
  }

  onSubmit(){
    this.auth.adminLogin(this.loginForm.value).subscribe(response => {
      location.href ="/admin";
    }, err => this.openSnackBar('El email o la contraseña son incorrectos', 'Aceptar'))
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}

import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { CasoClinicoService  } from "../../../utilities/servicios/caso_clinico.service";

@Component({
  selector: 'app-listado-caso-clinico',
  templateUrl: './listado-caso-clinico.component.html',
  styleUrls: ['./listado-caso-clinico.component.scss']
})

export class ListadoCasoClinicoComponent implements OnInit {

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['titulo','acciones','id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef
  
  constructor(
    private CasoClinicoService: CasoClinicoService,
    private snackBar: MatSnackBar
  ) {
    this.getCasosClinicos();
  }
  

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getCasosClinicos(): void {
    this.CasoClinicoService.getCasosClinicos()
    .subscribe(data => {
      this.dataSource = new MatTableDataSource(data.casos_clinicos);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}

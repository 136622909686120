import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  config;
  slides = [
    "/assets/images/404/image-1-404.jpg",
    "/assets/images/404/image-2-404.jpg",
    "/assets/images/404/image-3-404.jpg",
    "/assets/images/404/image-4-404.jpg"
  ]

  slidePrincipalConfig: any = {
    fade: true,
        cssEase: 'linear',
    speed: 500,  // this is the line that matters
  };

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  constructor(
    private router: Router,
  ) {
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      // This aborts all HTTP requests.
      this.ngUnsubscribe.next();
      // This completes the subject properlly.
      this.ngUnsubscribe.complete();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }


  ngAfterViewInit() {
    // This aborts all HTTP requests.
    this.ngUnsubscribe.next();
    // This completes the subject properlly.
    this.ngUnsubscribe.complete();
  }
}

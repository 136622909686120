import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { FaqsService } from "../utilities/servicios/faqs.service";

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  faqs = [];
  constructor(
    private faqsService: FaqsService,
    private renderer: Renderer2,
  ) {

  }

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
    adaptiveHeight: true,
    arrows: false,
  };

  ngOnInit() {
    this.getFaqs();
    this.buscador();
  }

  ngAfterViewInit() {

  }

  showFAQ(id) {
    $("#" + id).find('.arrowIcon').toggleClass("hiddenArrow");
    $("#" + id).parent().siblings('.answer').fadeToggle("fast");
  }

  getFaqs(): void {
    this.faqsService.getFaqs()
      .subscribe(data => {
        this.faqs = data.faqs.filter((items) => items.activo === true);
      });
  }

  buscador() {
    var search: any = document.getElementById("search"),
      faqs = document.getElementsByClassName("faq"),
      forEach = Array.prototype.forEach;

    search.addEventListener("keyup", function (e) {
      var choice = this.value;
      var i = 0;
      forEach.call(faqs, function (faq) {
        if (faq.innerHTML.toLowerCase().search(choice.toLowerCase()) == -1) {
          faq.style.display = "none";
          i++;
          if (i == faqs.length) {
            $('.noResults').show();
          }
        }
        else {
          faq.style.display = "block";
          $('.noResults').hide();
        }
      });
    }, false);
  }

  //Detectar si un elemento se encuentra visible en la pantalla
  public onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.renderer.addClass(target, visible ? 'active' : 'inactive');
    this.renderer.removeClass(target, visible ? 'inactive' : 'active');

    if ($(".group1").hasClass('active')) {
      if ($("#dynamicIMG").css('background-image') != 'url("https://api.klockner.es/assets/faqs/img1.jpg")') {
        //Cambiar imagen de fondo con transicion
        $("#dynamicIMG").fadeOut(function () {
          $("#dynamicIMG").css("background-image", "url('https://api.klockner.es/assets/faqs/img1.jpg')")
          $("#dynamicIMG").fadeIn();
        });
      }

      $(".progress-label").text('01');
      $(".progress-bar").css("height", "16.666666666666667%");
    }

    if ($(".group6").hasClass('active')) {
      if ($("#dynamicIMG").css('background-image') != 'url("https://api.klockner.es/assets/faqs/img2.jpg")') {
        //Cambiar imagen de fondo con transicion
        $("#dynamicIMG").fadeOut(function () {
          $("#dynamicIMG").css("background-image", "url('https://api.klockner.es/assets/faqs/img2.jpg')")
          $("#dynamicIMG").fadeIn();
        });
      }

      $(".progress-label").text('02');
      $(".progress-bar").css("height", "33.333333333333333%");
    }

    if ($(".group11").hasClass('active')) {
      if ($("#dynamicIMG").css('background-image') != 'url("https://api.klockner.es/assets/faqs/img3.jpg")') {
        //Cambiar imagen de fondo con transicion
        $("#dynamicIMG").fadeOut(function () {
          $("#dynamicIMG").css("background-image", "url('https://api.klockner.es/assets/faqs/img3.jpg')")
          $("#dynamicIMG").fadeIn();
        });
      }

      $(".progress-label").text('03');
      $(".progress-bar").css("height", "50%");
    }

    if ($(".group16").hasClass('active')) {
      if ($("#dynamicIMG").css('background-image') != 'url("https://api.klockner.es/assets/faqs/img4.jpg")') {
        //Cambiar imagen de fondo con transicion
        $("#dynamicIMG").fadeOut(function () {
          $("#dynamicIMG").css("background-image", "url('https://api.klockner.es/assets/faqs/img4.jpg')")
          $("#dynamicIMG").fadeIn();
        });
      }

      $(".progress-label").text('04');
      $(".progress-bar").css("height", "66.666666666666667%");
    }

    if ($(".group21").hasClass('active')) {
      if ($("#dynamicIMG").css('background-image') != 'url("https://api.klockner.es/assets/faqs/img5.png")') {
        //Cambiar imagen de fondo con transicion
        $("#dynamicIMG").fadeOut(function () {
          $("#dynamicIMG").css("background-image", "url('https://api.klockner.es/assets/faqs/img5.png')")
          $("#dynamicIMG").fadeIn();
        });
      }

      $(".progress-label").text('05');
      $(".progress-bar").css("height", "83.333333333333334%");
    }

    if ($(".group26").hasClass('active')) {
      if ($("#dynamicIMG").css('background-image') != 'url("https://api.klockner.es/assets/faqs/img6.jpg")') {
        //Cambiar imagen de fondo con transicion
        $("#dynamicIMG").fadeOut(function () {
          $("#dynamicIMG").css("background-image", "url('https://api.klockner.es/assets/faqs/img6.jpg')")
          $("#dynamicIMG").fadeIn();
        });
      }

      $(".progress-label").text('06');
      $(".progress-bar").css("height", "100%");
    }
  }
}

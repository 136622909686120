import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';


// Componentes Public
import { HomeComponent } from './home/home.component';
import { ContactoComponent } from './contacto/contacto.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaginasComponent } from './paginas/paginas.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { FaqsComponent } from './faqs/faqs.component';
import { BlogComponent } from './blog/blog.component';
import { DetalleBlogComponent } from './detalle-blog/detalle-blog.component';
import { EventosComponent } from './eventos/eventos.component';
import { DetalleEventoComponent } from './detalle-evento/detalle-evento.component';
import { DetalleProductoComponent } from './detalle-producto/detalle-producto.component';
import { FamiliaComponent } from './familia/familia.component';
import { ResultadosBuscadorComponent } from './resultados-buscador/resultados-buscador.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { CienciaComponent } from './ciencia/ciencia.component';
import { BibliotecaComponent } from './biblioteca/biblioteca.component';

//Componentes Admin
import { AdminLoginComponent } from './admin/login/login.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { AdminContactoComponent } from './admin/contacto/contacto.component';
import { ListadoProductoComponent } from './admin/producto/listado-producto/listado-producto.component';
import { NewProductoComponent } from './admin/producto/new-producto/new-producto.component';
import { EditProductoComponent } from './admin/producto/edit-producto/edit-producto.component';
import { ListadoBlogComponent } from './admin/blog/listado-blog/listado-blog.component';
import { NewBlogComponent } from './admin/blog/new-blog/new-blog.component';
import { EditBlogComponent } from './admin/blog/edit-blog/edit-blog.component';
import { ListadoPaginasComponent } from './admin/paginas/listado-paginas/listado-paginas.component';
import { NewPaginasComponent } from './admin/paginas/new-paginas/new-paginas.component';
import { EditPaginasComponent } from './admin/paginas/edit-paginas/edit-paginas.component';
import { ListadoEventoComponent } from './admin/evento/listado-evento/listado-evento.component';
import { NewEventoComponent } from './admin/evento/new-evento/new-evento.component';
import { EditEventoComponent } from './admin/evento/edit-evento/edit-evento.component';
import { ListadoPonenteComponent } from './admin/ponente/listado-ponente/listado-ponente.component';
import { NewPonenteComponent } from './admin/ponente/new-ponente/new-ponente.component';
import { EditPonenteComponent } from './admin/ponente/edit-ponente/edit-ponente.component';
import { ListadoCasoClinicoComponent } from './admin/caso-clinico/listado-caso-clinico/listado-caso-clinico.component';
import { NewCasoClinicoComponent } from './admin/caso-clinico/new-caso-clinico/new-caso-clinico.component';
import { EditCasoClinicoComponent } from './admin/caso-clinico/edit-caso-clinico/edit-caso-clinico.component';
import { ListadoDescargaComponent } from './admin/descarga/listado-descarga/listado-descarga.component';
import { EditDescargaComponent } from './admin/descarga/edit-descarga/edit-descarga.component';
import { NewDescargaComponent } from './admin/descarga/new-descarga/new-descarga.component';
import { NewFamiliaComponent } from './admin/familia/new-familia/new-familia.component';
import { EditFamiliaComponent } from './admin/familia/edit-familia/edit-familia.component';
import { ListadoFamiliaComponent } from './admin/familia/listado-familia/listado-familia.component';
import { EditMenuComponent } from './admin/menu/edit-menu/edit-menu.component';
import { ListadoFaqsComponent } from './admin/faqs/listado-faqs/listado-faqs.component';
import { EditFaqComponent } from './admin/faqs/edit-faq/edit-faq.component';
import { NewFaqComponent } from './admin/faqs/new-faq/new-faq.component';

//Playground
import { PlaygroundComponent } from './playground/playground.component';

const routes: Routes = [
  // PUBLIC ROUTES
  { path: '', component: HomeComponent, data: { title: 'Home' } },
  { path: 'home', redirectTo: '/', pathMatch: 'full', data: { title: 'Home' } },
  { path: 'politica-de-cookies', component: HomeComponent, data: { title: 'Home' } },
  { path: 'contacto', component: ContactoComponent, data: { title: 'Contacto' } },
  { path: 'contacto/gracias', component: ThankYouComponent, data: { title: 'Gracias por el Contacto' } },
  { path: 'pacientes', component: FaqsComponent, data: { title: 'Pacientes' } },
  { path: 'blog', component: BlogComponent, data: { title: 'Blog' } },
  { path: 'blog/:titulo', component: DetalleBlogComponent, data: { title: 'Noticia ' } },
  { path: 'eventos', component: EventosComponent, data: { title: 'Eventos' } },
  { path: 'eventos/:titulo', component: DetalleEventoComponent, data: { title: 'Evento' } },
  { path: 'producto/:familia/:titulo', component: DetalleProductoComponent, data: { title: 'Producto' } },
  { path: 'familia/Productos y soluciones', redirectTo: '/', pathMatch: 'full', data: { title: 'Home' } },
  { path: 'familia/:titulo', component: FamiliaComponent, data: { title: 'Familia' } },
  { path: 'resultados/:term', component: ResultadosBuscadorComponent, data: { title: 'Resultados Buscador' } },
  { path: 'empresa', component: EmpresaComponent, data: { title: 'Empresa' } },
  { path: 'ciencia', component: CienciaComponent, data: { title: 'Ciencia' } },
  { path: 'biblioteca', component: BibliotecaComponent, data: { title: 'Biblioteca' } },
  // ADMIN ROUTES
  { path: 'admin', component: AdminHomeComponent, data: { title: 'Panel de Control' } },
  { path: 'admin/login', component: AdminLoginComponent },
  { path: 'admin/contacto', component: AdminContactoComponent, data: { title: 'Contactos' } },
  { path: 'admin/producto/editor', component: ListadoProductoComponent, data: { title: 'Productos' } },
  { path: 'admin/producto/editar/:id', component: EditProductoComponent, data: { title: 'Producto' } },
  { path: 'admin/producto/nuevo', component: NewProductoComponent, data: { title: 'Producto' } },
  { path: 'admin/blog/editor', component: ListadoBlogComponent, data: { title: 'Blog' } },
  { path: 'admin/blog/editar/:id', component: EditBlogComponent, data: { title: 'Blog' } },
  { path: 'admin/blog/nuevo', component: NewBlogComponent, data: { title: 'Blog' } },
  { path: 'admin/paginas/editor', component: ListadoPaginasComponent, data: { title: 'Páginas' } },
  { path: 'admin/paginas/editar/:id', component: EditPaginasComponent, data: { title: 'Páginas' } },
  { path: 'admin/paginas/nuevo', component: NewPaginasComponent, data: { title: 'Páginas' } },
  { path: 'admin/evento/editor', component: ListadoEventoComponent, data: { title: 'Eventos' } },
  { path: 'admin/evento/editar/:id', component: EditEventoComponent, data: { title: 'Evento' } },
  { path: 'admin/evento/nuevo', component: NewEventoComponent, data: { title: 'Evento' } },
  { path: 'admin/ponente/editor', component: ListadoPonenteComponent, data: { title: 'Ponentes' } },
  { path: 'admin/ponente/editar/:id', component: EditPonenteComponent, data: { title: 'Ponente' } },
  { path: 'admin/ponente/nuevo', component: NewPonenteComponent, data: { title: 'Ponente' } },
  { path: 'admin/caso-clinico/editor', component: ListadoCasoClinicoComponent, data: { title: 'Casos Clinicos' } },
  { path: 'admin/caso-clinico/editar/:id', component: EditCasoClinicoComponent, data: { title: 'Caso Clinico' } },
  { path: 'admin/caso-clinico/nuevo', component: NewCasoClinicoComponent, data: { title: 'Caso Clinico' } },
  { path: 'admin/descarga/editor', component: ListadoDescargaComponent, data: { title: 'Descargas' } },
  { path: 'admin/descarga/editar/:id', component: EditDescargaComponent, data: { title: 'Descarga' } },
  { path: 'admin/descarga/nuevo', component: NewDescargaComponent, data: { title: 'Descarga' } },
  { path: 'admin/familia/editor', component: ListadoFamiliaComponent, data: { title: 'Familias' } },
  { path: 'admin/familia/editar/:id', component: EditFamiliaComponent, data: { title: 'Familia' } },
  { path: 'admin/familia/nuevo', component: NewFamiliaComponent, data: { title: 'Familia' } },
  { path: 'admin/menu/editor', component: EditMenuComponent, data: { title: 'Menús' } },
  { path: 'admin/faq/editar/:id', component: EditFaqComponent, data: { title: 'FAQ' } },
  { path: 'admin/faq/nuevo', component: NewFaqComponent, data: { title: 'FAQ' } },
  { path: 'admin/faq/editor', component: ListadoFaqsComponent, data: { title: "FAQ's" } },

  //Playground
  // { path: 'playground', component: PlaygroundComponent },

  //Error Routes
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404'},

  //DYNAMIC ROUTES
  // { path: ':url', component: PaginasComponent },
  // { path: ':url/:url2', component: PaginasComponent },
  // { path: ':url/:url2/:url3', component: PaginasComponent },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 64] // [x, y]
};



@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

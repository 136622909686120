import { Component, OnInit, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { ProductoService } from "../utilities/servicios/producto.service";
import { DescargaService } from "../utilities/servicios/descarga.service";
import { ContactoService } from "../utilities/servicios/contacto.service";
import { GlobalUtilities } from '../utilities/clases/global_utilities';
import { DomSanitizer } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { texto_legal_dialog } from '../partials/footer/footer.component';
import textos_legales from '../textos_legales.json';

// Dynamic slides to show
// import { SlickCarouselComponent } from "ngx-slick-carousel";

export interface DialogData {
  mensaje: string;
}

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.scss']
})
export class DetalleProductoComponent implements OnInit {
  producto: any;
  isBrowser;
  nombre: any;
  selectedFAQ: any;
  config;
  haveDescarga = false;
  evidenciaCientifica: any = [];
  formacionRelacionada: any = [];
  instruccionesUso: any = [];
  catalogosBrochures: any = [];
  listadoReferencias: any = [];
  selectedCategoria: any;
  selectedCategoriaTitle;
  selectedCategoriaDescription;
  renderedCat = false;

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
    adaptiveHeight: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    pauseOnFocus: false,
    infinite: true,
  };

  multiSlideConfig: any = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    speed: 300,
    infinite: true,
    autoplay: false,
    adaptiveHeight: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        }
      },
    ]
  };

  relatedMultiSlideConfig: any = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    speed: 300,
    infinite: true,
    autoplay: false,
    adaptiveHeight: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
        }
      },
    ]
  };

  slideVideoConfig: any = {
    dots: true,
    arrows: false,
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
  };

  constructor(
    private route: ActivatedRoute,
    private productoService: ProductoService,
    private DescargaService: DescargaService,
    private GlobalUtilities: GlobalUtilities,
    public sanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      //Same URL Router Events
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(async (event: NavigationEnd) => {
        this.haveDescarga = false;
        this.getProducto();
      });
      //END Same URL Router Events
    }
  }

  ngOnInit() {
    this.getProducto();
  }

  // Dynamic slides to show
  // @ViewChild("slickModal") slickModal: SlickCarouselComponent;

  ngAfterViewInit() {
    $("#question_0").addClass('activeFaq');
    $(".sectionHTML img").css("max-width", "100%");
    $(".sectionHTML a").css({
      "font-weight": "bold",
      "text-decoration": "underline",
      "color": "black"
    });

    // Dynamic slides to show
    // console.log(this.producto.relacionados);
    // console.log(this.relatedMultiSlideConfig.slidesToShow);
    // this.relatedMultiSlideConfig.slidesToShow = 1;
    // console.log(this.relatedMultiSlideConfig.slidesToShow);
    // this.slickModal.unslick();
    // this.slickModal.initSlick();
  }

  getProducto(): void {
    let familia = this.route.snapshot.paramMap.get('familia');
    //remove the dashes and add spaces
    if (familia) familia = familia.split('-').join(' ');

    let titulo = this.route.snapshot.paramMap.get('titulo');
    //remove the dashes and add spaces
    if (titulo) titulo = titulo.split('-').join(' ');

    if(titulo == 'T Scan®') titulo = 'T-Scan®';

    this.productoService.getProductoByName(String(familia), String(titulo))
      .subscribe(data => {
        this.producto = {};
        if (data.producto != undefined && data.producto.active) {
          this.producto = data.producto;
          this.selectedFAQ = this.producto.faqs[0];
          this.getDescargas();
        } else {
          window.location.href = '/404';
        }
      });
  }

  getDescargas(): void {
    this.DescargaService.getDescargas()
      .subscribe(data => {
        let id = this.producto._id;

        this.evidenciaCientifica = [];
        this.formacionRelacionada = [];
        this.instruccionesUso = [];
        this.catalogosBrochures = [];
        this.listadoReferencias = [];

        data.descargas.forEach(descarga => {
          if (descarga.productos.includes(id) && descarga.activo) {
            this.haveDescarga = true;
            switch (descarga.categoria) {
              case 'evidenciaCientifica':
                this.evidenciaCientifica.push(descarga);
                if (!this.renderedCat) {
                  this.selectedCategoria = this.evidenciaCientifica;
                  this.selectedCategoriaTitle = "Evidencia Científica";
                  // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
                  this.renderedCat = true;
                }
                break;
              case 'formacionRelacionada':
                this.formacionRelacionada.push(descarga);
                if (!this.renderedCat) {
                  this.selectedCategoria = this.formacionRelacionada;
                  this.selectedCategoriaTitle = "Formación Relacionada";
                  // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
                  this.renderedCat = true;
                }
                break;
              case 'instruccionesUso':
                this.instruccionesUso.push(descarga);
                if (!this.renderedCat) {
                  this.selectedCategoria = this.instruccionesUso;
                  this.selectedCategoriaTitle = "Instrucciones de Uso";
                  // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
                  this.renderedCat = true;
                }
                break;
              case 'catalogosBrochures':
                this.catalogosBrochures.push(descarga);
                if (!this.renderedCat) {
                  this.selectedCategoria = this.catalogosBrochures;
                  this.selectedCategoriaTitle = "Catálogos y Brochures";
                  // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
                  this.renderedCat = true;
                }
                break;
              case 'listadoReferencias':
                this.listadoReferencias.push(descarga);
                if (!this.renderedCat) {
                  this.selectedCategoria = this.listadoReferencias;
                  this.selectedCategoriaTitle = "Listado de Referencias";
                  // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
                  this.renderedCat = true;
                }
                break;
              default:
                break;
            }
          }
        });
      });
  }

  showCategoria(categoria) {
    switch (categoria) {
      case 'evidenciaCientifica':
        $(".categoria").removeClass('activeCategoria');
        $("#catEvidenciaCientifica").addClass('activeCategoria');
        this.selectedCategoria = this.evidenciaCientifica;
        this.selectedCategoriaTitle = "Evidencia Científica";
        // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
        break;
      case 'formacionRelacionada':
        $(".categoria").removeClass('activeCategoria');
        $("#catFormacionRelacionada").addClass('activeCategoria');
        this.selectedCategoria = this.formacionRelacionada;
        this.selectedCategoriaTitle = "Formación Relacionada";
        // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
        break;
      case 'instruccionesUso':
        $(".categoria").removeClass('activeCategoria');
        $("#catInstruccionesUso").addClass('activeCategoria');
        this.selectedCategoria = this.instruccionesUso;
        this.selectedCategoriaTitle = "Instrucciones de Uso";
        // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
        break;
      case 'catalogosBrochures':
        $(".categoria").removeClass('activeCategoria');
        $("#catCatalogosBrochures").addClass('activeCategoria');
        this.selectedCategoria = this.catalogosBrochures;
        this.selectedCategoriaTitle = "Catálogos y Brochures";
        // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
        break;
      case 'listadoReferencias':
        $(".categoria").removeClass('activeCategoria');
        $("#catListadoReferencias").addClass('activeCategoria');
        this.selectedCategoria = this.listadoReferencias;
        this.selectedCategoriaTitle = "Listado de Referencias";
        // this.selectedCategoriaDescription = "Nunc eu ipsum a leo volutpat sagittis. Curabitur tempor libero iaculis magna lobortis pretium. ";
        break;
      default:
        break;
    }
  }

  showFAQ(i) {
    $(".question").removeClass('activeFaq');
    $("#question_" + i).addClass('activeFaq');
    this.selectedFAQ = this.producto.faqs[i];
  }

  windowOpen(element) {
    switch (element) {
      case 'ficha_tecnica':
        element = `https://api.klockner.es/assets/fichas_tecnicas/${this.producto.ficha_tecnica}`;
        break;
      case 'guia_rapida':
        element = `https://api.klockner.es/assets/fichas_tecnicas/${this.producto.guia_rapida}`;
        break;
      default:
        break;
    }
    this.GlobalUtilities.windowOpen(element);
  }

  openDialog(element): void {
    let elementName = "";
    switch (element) {
      case 'ficha_tecnica':
        elementName = 'Información del Implante'
        element = `https://api.klockner.es/assets/fichas_tecnicas/${this.producto.ficha_tecnica}`;
        break;
      case 'guia_rapida':
        elementName = 'Guia Rápida'
        element = `https://api.klockner.es/assets/fichas_tecnicas/${this.producto.guia_rapida}`;
        break;
      default:
        break;
    }

    const dialogRef = this.dialog.open(ctaFichaTecnicaDialog, {
      width: '600px',
      panelClass: 'login-dialog-container',
      data: elementName
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result) {
        // window.open(`https://api.klockner.es/assets/descargas/${element}`, '_blank');
        window.open(`${element}`, '_blank');
      }
    });
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }
}

@Component({
  selector: 'ctaDescarga',
  templateUrl: './ctaDescarga.html',
  styleUrls: ['./detalle-producto.component.scss']
})

export class ctaFichaTecnicaDialog {

  ctaForm = this.fb.group({
    nombre: ['', Validators.required],
    email: ['', Validators.email],
    source: [''],
    consent: new FormControl(false),
  });

  constructor(
    public dialogRef: MatDialogRef<ctaFichaTecnicaDialog>,
    private snackBar: MatSnackBar,
    private contactoService: ContactoService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.ctaForm.controls['source'].setValue(data);
  }

  cta() {
    this.contactoService.createCta(this.ctaForm.value).subscribe(data => {
      this.dialogRef.close(true)
    }, err => {

    })
  }

  public closeDialog() {
    this.dialogRef.close()
  }

  openPolitica() {
    const dialogRef = this.dialog.open(texto_legal_dialog, {
      width: '600px',
      data: { titulo: textos_legales.politica_de_privacidad.titulo, mensaje: textos_legales.politica_de_privacidad.texto }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
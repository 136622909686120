import { Component, OnInit } from '@angular/core';
import { EventoService } from "../../utilities/servicios/evento.service";
import { Router, ActivatedRoute, NavigationEnd, ActivationEnd, ActivationStart } from '@angular/router';

@Component({
  selector: 'app-partial-eventos',
  templateUrl: './partial-eventos.component.html',
  styleUrls: ['./partial-eventos.component.scss']
})
export class PartialEventosComponent implements OnInit {
  eventos: any = [];
  config: any;
  emptyEventos = true;

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
    arrows: false,
  };

  constructor(
    private eventoService: EventoService,
    private router: Router, 
  ) { }

  ngOnInit() {
    this.getEventos();
  }

  getEventos() {
    this.eventoService.getEventos()
      .subscribe(data => {
        this.eventos = data.eventos.filter((items) => items.activo === true);
        if (this.eventos.length > 0)
          this.emptyEventos = false;
      });
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DelegacionService {

  private delegacionsURL = 'https://api.klockner.es/delegacion/all';
  private delegacionURL = 'https://api.klockner.es/delegacion';
  private delegacionURLbyID = 'https://api.klockner.es/delegacion/id';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer '+this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  //Crear articulo delegacion.
  createDelegacion (payload) {
    return this.http.post(this.delegacionURLbyID, payload, this.httpOptions)
    .pipe(
      tap(_ => {
        // console.log(_);
      })
    );
  }

  //Actualizar articulo delegacion.
  updateDelegacion (payload, id) {
    return this.http.put(this.delegacionURLbyID + "/" + id , payload, this.httpOptions)
    .pipe(
      tap(_ => {
        // console.log(_);
      })
    );
  }

  //Recupera todos los articulos del delegacion.
  getDelegaciones() {
    return this.http.get<any>(this.delegacionsURL)
      .pipe(
        tap(_ => {
          // console.log(_);
        })
      );
  }

  //Recupera un articulo de delegacion por su id.
  getDelegacion (id: String): Observable<any> {
    const url = `${this.delegacionURLbyID}/${id}`;
    return this.http.get<any>(url, {
      withCredentials: true 
    })
    .pipe(
      tap(_ => {
        // console.log(_)
      })
    );
  } 
  getDelegacionByTitulo (titulo: String): Observable<any> {
    const url = `${this.delegacionURL}/${titulo}`;
    return this.http.get<any>(url, {
      withCredentials: true 
    })
    .pipe(
      tap(_ => {
        // console.log(_)
      })
    );
  } 


}

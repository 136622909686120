import { Component, OnInit } from '@angular/core';
import { DescargaService } from "../utilities/servicios/descarga.service";
import { GlobalUtilities } from '../utilities/clases/global_utilities';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-biblioteca',
  templateUrl: './biblioteca.component.html',
  styleUrls: ['./biblioteca.component.scss']
})
export class BibliotecaComponent implements OnInit {
  descargas: any = [];
  destacados: any = [];
  categorias: any = [];
  term: any;
  selectedCategoria;
  p: any;

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
    arrows: false,
  };

  constructor(
    private DescargaService: DescargaService,
    private GlobalUtilities: GlobalUtilities,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.getDestacados();
    this.getDescargas();
    this.showFiltros('open');
  }

  getDestacados() {
    this.DescargaService.getDestacados()
      .subscribe(data => {
        this.destacados = data.descargas.filter((items) => items.activo === true);
      });
  }

  getDescargas(): void {
    this.selectedCategoria = "";
    this.term = "";

    this.DescargaService.getDescargas()
      .subscribe(data => {
        let descargas = data.descargas.filter((items) => items.activo == true);

        descargas.forEach(element => {
          switch (element.categoria) {
            case 'evidenciaCientifica':
              element.categoria = "Evidencia Científica"
              break;
            case 'formacionRelacionada':
              element.categoria = "Formación Relacionada"
              break;
            case 'instruccionesUso':
              element.categoria = "Instrucciones de Uso"
              break;
            case 'catalogosBrochures':
              element.categoria = "Catálogos y Brochures"
              break;
            case 'listadoReferencias':
              element.categoria = "Listado de Referencias"
              break;
            default:
              element.categoria = element.categoria
              break;
          }
        });

        descargas.forEach(descarga => {
          this.categorias.indexOf(descarga.categoria) === -1 ? this.categorias.push(descarga.categoria) : '';
        });

        this.descargas = descargas;
      });
  }

  windowOpen(element) {
    window.open(`https://api.klockner.es/assets/descargas/${element}`, '_blank');
  }

  showFiltros(event) {
    if (event == 'open') {
      $("#filtrosBuscadorEventos").slideDown("500", function () { });
    } else {
      $("#filtrosBuscadorEventos").slideUp("500", function () { });
    }
    $(".buscadorEventosTrigger").toggle();
  }

  searchEventos() {
    var term = this.term;

    if (term == "")
      return this.openSnackBar('¡Ups! Debes escribir qué quieres buscar.', 'Aceptar');

    this.DescargaService.searchEventos(term)
      .subscribe(data => {
        this.descargas = data.descargas.filter((items) => items.activo === true);
        data.descargas.forEach(descarga => {
          this.categorias.indexOf(descarga.categoria) === -1 ? this.categorias.push(descarga.categoria) : '';
        });
      });
  }

  filtrarEventos() {
    let filtroCategoria;

    switch (this.selectedCategoria) {
      case 'Evidencia Científica':
        filtroCategoria = "evidenciaCientifica"
        break;
      case 'Formación Relacionada':
        filtroCategoria = "formacionRelacionada"
        break;
      case 'Instrucciones de Uso':
        filtroCategoria = "instruccionesUso"
        break;
      case 'Catálogos y Brochures':
        filtroCategoria = "catalogosBrochures"
        break;
      case 'Listado de Referencias':
        filtroCategoria = "listadoReferencias"
        break;
      default:
        filtroCategoria = ""
        break;
    }

    var filters = JSON.stringify({
      term: this.term,
      categoria: filtroCategoria,
    });

    if (filtroCategoria == "")
      return this.openSnackBar('¡Ups! Debes seleccionar algún filtro para buscar.', 'Aceptar');

    this.DescargaService.filterDescargas(filters)
      .subscribe(data => {
        this.descargas = data.descargas.filter((items) => items.activo === true);

        this.descargas.forEach(element => {
          switch (element.categoria) {
            case 'evidenciaCientifica':
              element.categoria = "Evidencia Científica"
              break;
            case 'formacionRelacionada':
              element.categoria = "Formación Relacionada"
              break;
            case 'instruccionesUso':
              element.categoria = "Instrucciones de Uso"
              break;
            case 'catalogosBrochures':
              element.categoria = "Catálogos y Brochures"
              break;
            case 'listadoReferencias':
              element.categoria = "Listado de Referencias"
              break;
            default:
              element.categoria = element.categoria
              break;
          }
        });

        data.descargas.forEach(descarga => {
          this.categorias.indexOf(descarga.categoria) === -1 ? this.categorias.push(descarga.categoria) : '';
        });
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

}

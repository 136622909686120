import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { MenuService } from "../../../utilities/servicios/menu.service";
import { ProductoService } from "../../../utilities/servicios/producto.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-familia',
  templateUrl: './listado-familia.component.html',
  styleUrls: ['./listado-familia.component.scss']
})
export class ListadoFamiliaComponent implements OnInit {
  menus: any = [];
  productos: any = [];

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['titulo', 'lvl', 'acciones', 'id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  constructor(
    private MenuService: MenuService,
    private ProductoService: ProductoService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {
    this.getMenus();
    this.getProductos();
  }

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changeACTIVE(idMenu, activechange) {
    let child = false;

    if(!activechange){
      this.menus.forEach(menu => {
        if (menu.parent == idMenu && menu.activo) {
          this.openSnackBar("No se puede desactivar una familia que contiene subfamilia", "Aceptar")
          child = true;
          return false
        }
      });
  
      this.productos.forEach(producto => {
        if (producto.familia[0]._id == idMenu && producto.active) {
          this.openSnackBar("No se puede desactivar una familia que contiene productos", "Aceptar")
          child = true;
          return false
        }
      });
    }

    if (!child)
      this.MenuService.changeActive(idMenu, activechange)
        .subscribe(response => {
          this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
          location.reload();
        });
  }

  getMenus(): void {
    this.menus = [];
    this.MenuService.getMenus()
      .subscribe(data => {
        data.menus.forEach(element => {
          if (element.lvl != 0)
            this.menus.push(element)
        });
        this.dataSource = new MatTableDataSource(this.menus);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  getProductos(): void {
    this.ProductoService.getProductos()
      .subscribe(data => {
        this.productos = data.productos;
      });
  }
}

import { Component, OnInit, Inject, ElementRef, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { ActivatedRoute } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { DescargaService } from "../../../utilities/servicios/descarga.service";
import { ProductoService } from "../../../utilities/servicios/producto.service";

import { MatSnackBar } from '@angular/material';
import { AuthService } from "../../../utilities/servicios/auth.service";

@Component({
  selector: 'app-edit-descarga',
  templateUrl: './edit-descarga.component.html',
  styleUrls: ['./edit-descarga.component.scss']
})
export class EditDescargaComponent implements OnInit {
  isBrowser;
  descarga: any;
  productos: any;
  selectedProductos = [];
  selectedCategoria;
  selectedPDF: File;

  descargaForm = this.fb.group({
    titulo: ['', Validators.required],
    descripcion: ['', Validators.required],
    archivo: [''],
  })

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private DescargaService: DescargaService,
    private ProductoService: ProductoService,
    private snackBar: MatSnackBar,
    private auth: AuthService,
    private elRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    this.getDescarga();
    this.getProductos();
  }

  onPDFChanged(event) {
    this.selectedPDF = event.target.files[0];
  }

  changeProducto(event) {
    this.selectedProductos = event.value;
  }

  changeCategoria(event) {
    this.selectedCategoria = event.value;
  }

  getDescarga(): void {
    let id = this.route.snapshot.paramMap.get('id');
    this.DescargaService.getDescarga(String(id))
      .subscribe(data => {
        this.descarga = data.descarga;
        this.selectedCategoria = this.descarga.categoria;
        this.selectedProductos = this.descarga.productos;
        this.descargaForm.controls['titulo'].setValue(this.descarga.titulo);
        this.descargaForm.controls['descripcion'].setValue(this.descarga.descripcion);
        this.descargaForm.controls['archivo'].setValue(this.descarga.archivo);
      });
  }

  getProductos(): void {
    this.ProductoService.getProductos()
      .subscribe(data => {
        this.productos = data.productos;
      });
  }


  onSubmit() {
    let descarga = {
      categoria: this.selectedCategoria,
      productos: this.selectedProductos
    }

    var input = new FormData();
    input.append('descarga', JSON.stringify(descarga));
    input.append('descargaForm', JSON.stringify(this.descargaForm.value));

    if (this.selectedPDF) {
      input.append('pdf', this.selectedPDF, this.selectedPDF.name);
    }

    let id = this.route.snapshot.paramMap.get('id');
    this.DescargaService.updateDescarga(input, id).subscribe(response => {
      this.openSnackBar('Descarga modificada correctamente', 'Aceptar');
    }, err => {
      this.openSnackBar('Ha ocurrido un error modificando la descarga', 'Aceptar')
      if (err.status == 401) {
        this.auth.logout(true);
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}


import { Component, OnInit } from '@angular/core';
import { EventoService } from "../utilities/servicios/evento.service";
import { PonenteService } from "../utilities/servicios/ponente.service";
import { GlobalUtilities } from '../utilities/clases/global_utilities';
import { MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})
export class EventosComponent implements OnInit {
  eventos: any = [];
  destacados: any = [];
  config: any;
  ponentes: any = [];
  ciudades = [];
  term: any;
  selectedCiudad;
  selectedPais;
  selectedPonente;
  selectedStartDate;
  selectedEndDate;

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
    arrows: false,
  };

  constructor(
    private eventoService: EventoService,
    private ponenteService: PonenteService,
    private utilities: GlobalUtilities,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getDestacados();
    this.getEventos();
    this.getPonentes();
    this.showFiltros('open');
  }

  getDestacados() {
    this.eventoService.getDestacados()
      .subscribe(data => {
        this.destacados = data.eventos.filter((items) => items.activo === true);
      });
  }

  cleanEventos() {
    this.term = "";
    this.selectedCiudad = "";
    this.selectedPais = "";
    this.selectedPonente = "";
    this.selectedStartDate = "";
    this.selectedEndDate = "";

    this.eventoService.getEventos()
      .subscribe(data => {
        this.eventos = data.eventos.filter((items) => items.activo === true);
        data.eventos.forEach(evento => {
          this.ciudades.indexOf(evento.ubicacion.poblacion) === -1 ? this.ciudades.push(evento.ubicacion.poblacion) : '';
        });
      });
  }

  getEventos() {
    if (history.state.buscadorEventos != undefined) {
      if (history.state.buscadorEventos.data.eventos.length > 0) {
        this.eventos = [];
      } else {
        this.eventos = history.state.buscadorEventos.data.eventos.filter((items) => items.activo === true);
        history.state.buscadorEventos.data.eventos.forEach(evento => {
          this.ciudades.indexOf(evento.ubicacion.poblacion) === -1 ? this.ciudades.push(evento.ubicacion.poblacion) : '';
        });
      }
    } else {
      this.eventoService.getEventos()
        .subscribe(data => {
          this.eventos = data.eventos.filter((items) => items.activo === true);
          data.eventos.forEach(evento => {
            this.ciudades.indexOf(evento.ubicacion.poblacion) === -1 ? this.ciudades.push(evento.ubicacion.poblacion) : '';
          });
        });
    }
  }

  getPonentes() {
    this.ponenteService.getPonentes()
      .subscribe(data => {
        this.ponentes = data.ponentes;
      });
  }

  searchEventos() {
    var term = this.term;

    if (term == "")
      return this.openSnackBar('¡Ups! Debes escribir qué quieres buscar.', 'Aceptar');

    this.eventoService.searchEventos(term)
      .subscribe(data => {
        this.eventos = data.eventos.filter((items) => items.activo === true);
        data.eventos.forEach(evento => {
          this.ciudades.indexOf(evento.ubicacion.poblacion) === -1 ? this.ciudades.push(evento.ubicacion.poblacion) : '';
        });
      });
  }

  filtrarEventos() {
    var filters = JSON.stringify({
      term: this.term,
      ciudad: this.selectedCiudad,
      pais: this.selectedPais,
      ponente: this.selectedPonente,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate
    });

    if (this.selectedCiudad == "" && this.selectedPais == "" && this.selectedPonente == "" && this.selectedStartDate == "" && this.selectedEndDate == "")
      return this.openSnackBar('¡Ups! Debes seleccionar algún filtro para buscar.', 'Aceptar');

    this.eventoService.filterEventos(filters)
      .subscribe(data => {
        this.eventos = data.eventos.filter((items) => items.activo === true);
        data.eventos.forEach(evento => {
          this.ciudades.indexOf(evento.ubicacion.poblacion) === -1 ? this.ciudades.push(evento.ubicacion.poblacion) : '';
        });
      });
  }

  showFiltros(event) {
    if (event == 'open') {
      $("#filtrosBuscadorEventos").slideDown("500", function () { });
    } else {
      $("#filtrosBuscadorEventos").slideUp("500", function () { });
    }
    $(".buscadorEventosTrigger").toggle();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }
}

import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService } from './utilities/servicios/auth.service';
import { Router, NavigationEnd, ActivationEnd, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  titulo: any;
  admin: any = 'public';
  Adminlogin;
  isBrowser;
  mobileGrid;

  // CUSTOM CURSOR
  top: any;
  left: any;
  expand = false;

  constructor(
    private router: Router,
    private auth: AuthService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.getScreenSizeStart();
      this.getScreenSize();
      //Google Analytics Router Events
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
      //END Google Analytics Router Events

      //Check Session Router Events
      router.events.pipe(
        filter(event => event instanceof ActivationEnd)
      ).subscribe(async (event: ActivationEnd) => {
        this.titulo = event.snapshot.data['title'];
        if (this.router.url.indexOf('/admin') > -1) {
          var checkSession = await this.auth.checkSession(true)
          if (checkSession) {
            if (this.router.url.indexOf('/admin/login') == 0) {
              this.admin = 'admin';
              this.router.navigate(['/admin']);
            }
            this.admin = 'admin';
          } else {
            this.admin = 'login';
            if (this.router.url.indexOf('/admin/login') != 0) this.router.navigate(['/admin/login'])
          }
        } else {
          this.admin = 'public'
        }
      });
      //END Check Session Router Events

      //SCROLL TOP ON ROUTER NAV
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(function () {
      $("#loader").fadeOut();
   }, 5000);

    if (this.admin = 'admin') {

    }
  }

  // CUSTOM CURSOR
  @HostListener('document:click', ['$event'])
  onClick($event) {
    this.expand = true;
    setTimeout(() => {
      this.expand = false;
    }, 500)
  }

  @HostListener('document:mousemove', ['$event'])
  onMousemove($event) {
    this.top = ($event.pageY - 10) + "px";
    this.left = ($event.pageX - 10) + "px";
  }
  // END CUSTOM CURSOR

  ngOnInit() {
    if (this.isBrowser) {
      $(".loader").delay(5000).fadeOut();
    }
  }

  admin_logout() {
    this.auth.logout(true);
  }

  // Grid Events
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 768)
      this.mobileGrid = true;
    else
      this.mobileGrid = false;
  }

  getScreenSizeStart() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 768) this.mobileGrid = true;
    else this.mobileGrid = false;
  }
}
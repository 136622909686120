import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Producto } from '../../../utilities/clases/producto';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { ProductoService } from "../../../utilities/servicios/producto.service";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-listado-producto',
  templateUrl: './listado-producto.component.html',
  styleUrls: ['./listado-producto.component.scss']
})

export class ListadoProductoComponent implements OnInit {
  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<Producto>;

  displayedColumns: string[] = ['nombre', 'familia', 'acciones', 'id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  constructor(
    private productoService: ProductoService,
    private snackBar: MatSnackBar
  ) {
    this.getProductos();
  }

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getProductos(): void {
    this.productoService.getProductos()
      .subscribe(data => {
        //PERMITIR FILTRAR COLUMNAS DEL POPULATE
        data.productos.forEach(element => {
          element.familia = element.familia[0].titulo
        });

        this.dataSource = new MatTableDataSource(data.productos);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  changeACTIVE(idProduct, id_template) {
    this.productoService.changeActive(idProduct, id_template)
      .subscribe(response => {
        this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
        location.reload();
      });
  }

  ExportTOExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    ws['!cols'] = [];
    ws['!cols'][2] = { hidden: true };
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'listado_contacto');

    /* save to file */
    XLSX.writeFile(wb, 'listado_contacto.xlsx');
  }

  destacar(id, evento) {
    this.productoService.destacarProducto(id, evento.checked).subscribe(data => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
    }, err => {
      this.openSnackBar("Ya hay tres productos destacados", "Aceptar")
      evento.source.checked = false;
    })
  }

  onSale(id, evento) {
    this.productoService.changeOnSale(id, evento.checked).subscribe(data => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
    }, err => {
      this.openSnackBar("Ha habido un error modificando el producto", "Aceptar")
    })
  }
}

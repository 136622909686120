
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}

@Injectable({
  providedIn: 'root'
})

export class CasoClinicoService {

  private casos_clinicosURL = 'https://api.klockner.es/caso_clinico/all';
  private caso_clinicoURL = 'https://api.klockner.es/caso_clinico';
  private caso_clinicoNameURL = 'https://api.klockner.es/caso_clinico/name';
  private exceptionsURL = 'https://api.klockner.es/caso_clinico/editar/relateds/';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer '+this.cookieService.get("admin_token"),
    })
  };
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
  }

  getExceptions (data): Observable<any> {
    return this.http.post<any>(this.exceptionsURL, {exceptions: data} , this.httpOptions)
  }
  
  getCasosClinicos (): Observable<any> {
    return this.http.get<any>(this.casos_clinicosURL,  {
      withCredentials: true 
    })
  }

  getCasoClinico (id: String): Observable<any> {
    const url = `${this.caso_clinicoURL}/${id}`;
    return this.http.get<any>(url, {
      withCredentials: true 
    })
  } 

  getCasoClinicoByName (name: String): Observable<any> {
    const url = `${this.caso_clinicoNameURL}/${name}`;
    return this.http.get<any>(url)
  } 
  
  updateCasoClinico (payload, id) {
    return this.http.put(this.caso_clinicoURL + "/" + id , payload, this.httpOptions)
  }
  
  createCasoClinico (payload) {
    return this.http.post(this.caso_clinicoURL, payload, this.httpOptions)
  }
}
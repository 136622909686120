import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class EventoService {

  private eventosURL = 'https://api.klockner.es/evento/all';
  private eventoURL = 'https://api.klockner.es/evento';
  private eventoURLbyID = 'https://api.klockner.es/evento/id';
  private changeactiveURL = 'https://api.klockner.es/evento/active/';
  private searchEventosURL = 'https://api.klockner.es/evento/search/';
  private filterEventosURL = 'https://api.klockner.es/evento/search/filter/';
  private destacarURL = 'https://api.klockner.es/evento/destacar/';
  private destacadosURL = 'https://api.klockner.es/evento/destacados/all';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  //Crear evento.
  createEvento(payload) {
    return this.http.post(this.eventoURL, payload, this.httpOptions)
  }

  //Actualizar evento.
  updateEvento(payload, id) {
    return this.http.put(this.eventoURL + "/" + id, payload, this.httpOptions)
  }

  //Recupera todos los eventos.
  getEventos() {
    return this.http.get<any>(this.eventosURL)
  }

  //Recupera un evento por su id.
  getEvento(id: String): Observable<any> {
    const url = `${this.eventoURLbyID}/${id}`;
    return this.http.get<any>(url)
  }

  //Recupera un evento por su nombre.
  getEventoByTitulo(titulo: String): Observable<any> {
    const url = `${this.eventoURL}/${titulo}`;
    return this.http.get<any>(url)
  }

  //Cambia el estado de un evento activo/inactivo.
  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }

  //Recupera los articulos del blog destacados.
  getDestacados() {
    return this.http.get<any>(this.destacadosURL, this.httpOptions)
  }

  //Cambia el estado de un articulo destacado/no destacado.
  destacar(idProduct, destacar) {
    var payload = { destacar: destacar }
    return this.http.put(this.destacarURL + idProduct, payload, this.httpOptions)
  }


  getCoordinates(direccion) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${direccion}&key=AIzaSyA8zB93MrNmuHKG0DbllBO9Y_svpoIkD5w`;
    return this.http.get<any>(url)
  }

  searchEventos(term) {
    return this.http.get<any>(this.searchEventosURL + term)
  }

  filterEventos(payload) {
    return this.http.get<any>(this.filterEventosURL + payload)
  }
}

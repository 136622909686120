import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { PaginasService } from "../../../utilities/servicios/paginas.service";

@Component({
  selector: 'app-listado-paginas',
  templateUrl: './listado-paginas.component.html',
  styleUrls: ['./listado-paginas.component.scss']
})
export class ListadoPaginasComponent implements OnInit {

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['nombre', 'url', 'acciones', 'id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  constructor(
    private paginasService: PaginasService,
    private snackBar: MatSnackBar
  ) {
    this.getPaginas();
  }


  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPaginas(): void {
    this.paginasService.getPaginas()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data.paginas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  changeACTIVE(idPagina, activechange) {
    this.paginasService.changeActive(idPagina, activechange)
      .subscribe(response => {
        this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
        location.reload();
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private blogsURL = 'https://api.klockner.es/blog/all';
  private blogURL = 'https://api.klockner.es/blog';
  private blogURLbyID = 'https://api.klockner.es/blog/id';
  private categoriasURL = 'https://api.klockner.es/blog/categorys/';
  private blogsCategoriaURL = 'https://api.klockner.es/blog/category/';
  private destacarURL = 'https://api.klockner.es/blog/destacar/';
  private changeactiveURL = 'https://api.klockner.es/blog/active/';
  private relatedExceptionsURL = 'https://api.klockner.es/blog/editar/relateds/';
  private destacadosURL = 'https://api.klockner.es/blog/destacados/';
  private searchBlogsURL = 'https://api.klockner.es/blog/search/';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {

  }

  //Crear articulo blog.
  createBlog(payload) {
    return this.http.post(this.blogURL, payload, this.httpOptions)
  }

  //Actualizar articulo blog.
  updateBlog(payload, id) {
    return this.http.put(this.blogURLbyID + "/" + id, payload, this.httpOptions)
  }

  //Recupera todos los articulos del blog.
  getBlogs() {
    return this.http.get<any>(this.blogsURL)
  }

  //Recupera un articulo de blog por su id.
  getBlog(id: String): Observable<any> {
    const url = `${this.blogURLbyID}/${id}`;
    return this.http.get<any>(url)
  }

  getBlogByName(nombre: String): Observable<any> {
    const url = `${this.blogURL}/${nombre}`;
    return this.http.get<any>(url)
  }

  //Recupera las categorias de los articulos de blog.
  getCategorias() {
    return this.http.get<any>(this.categoriasURL)
  }

  //Recupera los articulos de blog filtrados por categoria.
  getBlogsByCategorias(categoria) {
    return this.http.get<any>(this.blogsCategoriaURL + categoria)
  }

  //Cambia el estado de un articulo destacado/no destacado.
  destacarBlog(idProduct, destacar) {
    var payload = { destacar: destacar }
    return this.http.put(this.destacarURL + idProduct, payload, this.httpOptions)
  }

  //Cambia el estado de un articulo activo/inactivo.
  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }

  //Recupera los articulos del blog que permitimos asignar como relacionados, descartando los que ya estan seleccionados y el mismo articulo.
  getExceptions(data): Observable<any> {
    return this.http.post<any>(this.relatedExceptionsURL, { exceptions: data }, this.httpOptions)
  }

  //Recupera los articulos del blog destacados.
  destacados() {
    return this.http.get<any>(this.destacadosURL, this.httpOptions)
  }

  getCatagoriasName(categoia) {
    return this.http.get<any>(this.categoriasURL + categoia)
  }

  searchBlogs(term) {
    return this.http.get<any>(this.searchBlogsURL + term)
  }
}

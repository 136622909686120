
import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Producto } from '../clases/producto';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}

@Injectable({
  providedIn: 'root'
})

export class ProductoService {

  private productosURL = 'https://api.klockner.es/producto/all';
  private productoURL = 'https://api.klockner.es/producto';
  private productoNameURL = 'https://api.klockner.es/producto/name';
  private productoCategoryURL = 'https://api.klockner.es/producto/category/';
  private productosOrderCategoryURL = 'https://api.klockner.es/producto/categorys';
  private changeactiveURL = 'https://api.klockner.es/producto/active/';
  private destacarURL = 'https://api.klockner.es/producto/destacar/';
  private changeOnSaleURL = 'https://api.klockner.es/producto/on_sale/';
  private destacadosURL = 'https://api.klockner.es/producto/destacados/all';
  private exceptionsURL = 'https://api.klockner.es/producto/editar/relateds/';
  private searchProductosURL = 'https://api.klockner.es/producto/search/';

  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer ' + this.cookieService.get("admin_token"),
    })
  };
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
  }

  getExceptions(data): Observable<Producto> {
    return this.http.post<Producto>(this.exceptionsURL, { exceptions: data }, this.httpOptions)
  }

  getProductos(): Observable<Producto> {
    return this.http.get<Producto>(this.productosURL)
  }

  getProducto(id: String): Observable<Producto> {
    const url = `${this.productoURL}/${id}`;
    return this.http.get<Producto>(url)
  }
  getProductoByName(familia: String, name: String): Observable<Producto> {
    const url = `${this.productoNameURL}/${familia}/${name}`;
    return this.http.get<Producto>(url)
  }

  updateProducto(payload, id) {
    return this.http.put(this.productoURL + "/" + id, payload, this.httpOptions)
  }

  createProducto(payload) {
    return this.http.post(this.productoURL, payload, this.httpOptions)
  }

  changeActive(idProduct, activechange) {
    var payload = { active: activechange }
    return this.http.put(this.changeactiveURL + idProduct, payload, this.httpOptions)
  }

  destacarProducto(idProduct, destacar) {
    var payload = { destacar: destacar }
    return this.http.put(this.destacarURL + idProduct, payload, this.httpOptions)
  }
  changeOnSale(idProduct, on_sale) {
    var payload = { on_sale: on_sale }
    return this.http.put(this.changeOnSaleURL + idProduct, payload, this.httpOptions)
  }

  getDestacados() {
    return this.http.get<any>(this.destacadosURL, this.httpOptions)
  }

  getProductosOrderByCategory() {
    return this.http.get<Producto>(this.productosOrderCategoryURL)
  }

  getProductosByCategory(category) {
    return this.http.get<Producto>(this.productoCategoryURL + category)
  }

  searchProductos(term) {
    return this.http.get<any>(this.searchProductosURL + term)
  }
}
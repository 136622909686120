import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { PonenteService  } from "../../../utilities/servicios/ponente.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-ponente',
  templateUrl: './listado-ponente.component.html',
  styleUrls: ['./listado-ponente.component.scss']
})

export class ListadoPonenteComponent implements OnInit {

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['nombre','acciones','id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef
  
  constructor(
    private PonenteService: PonenteService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {
    this.getPonentes();
  }
  

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPonentes(): void {
    this.PonenteService.getPonentes()
    .subscribe(data => {
      this.dataSource = new MatTableDataSource(data.ponentes);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}

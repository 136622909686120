import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { Blog } from '../../../utilities/clases/blog';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { BlogService  } from "../../../utilities/servicios/blog.service";

@Component({
  selector: 'app-listado-blog',
  templateUrl: './listado-blog.component.html',
  styleUrls: ['./listado-blog.component.scss']
})

export class ListadoBlogComponent implements OnInit {

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['titulo','acciones','id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef
  
  constructor(
    private blogService: BlogService,
    private snackBar: MatSnackBar
  ) {
    this.getBlogs();
  }
  

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getBlogs(): void {
    this.blogService.getBlogs()
    .subscribe(data => {
      this.dataSource = new MatTableDataSource(data.blogs);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  changeACTIVE(idBlog, activechange){
    this.blogService.changeActive(idBlog, activechange)
    .subscribe(response => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
      location.reload();
    });
  }

  destacar(id, evento){
    this.blogService.destacarBlog(id, evento.checked).subscribe(data => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
    }, err => {
      this.openSnackBar("No se pueden destacar mas elementos", "Aceptar")
      evento.source.checked = false;
    })
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { DescargaService } from "../../../utilities/servicios/descarga.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-listado-descarga',
  templateUrl: './listado-descarga.component.html',
  styleUrls: ['./listado-descarga.component.scss']
})
export class ListadoDescargaComponent implements OnInit {

  //Para poner la información en la dataTable
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = ['titulo', 'categoria', 'fecha', 'acciones', 'id'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('exportTable') table: ElementRef

  constructor(
    private DescargaService: DescargaService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe
  ) {
    this.getDescargas();
  }

  ngOnInit() {

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getDescargas(): void {
    this.DescargaService.getDescargas()
      .subscribe(data => {
        //PERMITIR FILTRAR COLUMNAS DEL POPULATE
        data.descargas.forEach(element => {
          element.fecha = this.datePipe.transform(element.fecha)
          switch (element.categoria) {
            case 'evidenciaCientifica':
              element.categoria = "Evidencia Científica"
              break;
            case 'formacionRelacionada':
              element.categoria = "Formación Relacionada"
              break;
            case 'instruccionesUso':
              element.categoria = "Instrucciones de Uso"
              break;
            case 'catalogosBrochures':
              element.categoria = "Catálogos y Brochures"
              break;
            case 'listadoReferencias':
              element.categoria = "Listado de Referencias"
              break;
            default:
              element.categoria = element.categoria
              break;
          }
        });

        this.dataSource = new MatTableDataSource(data.descargas);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  changeACTIVE(idDescarga, activechange) {
    this.DescargaService.changeActive(idDescarga, activechange)
      .subscribe(response => {
        this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
        location.reload();
      });
  }

  destacar(id, descarga) {
    this.DescargaService.destacar(id, descarga.checked).subscribe(data => {
      this.openSnackBar("Se ha completado la acción correctamente", "Aceptar")
    }, err => {
      this.openSnackBar("No se pueden destacar mas elementos", "Aceptar")
      descarga.source.checked = false;
    })
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  private sessionsURL = 'https://api.klockner.es/analytics/sessions/';  // URL to web api
  private bounceRateURL = 'https://api.klockner.es/analytics/bounceRate/';  // URL to web api
  private pagePathURL = 'https://api.klockner.es/analytics/pagePath/';  // URL to web api
  private DeviceConnectionURL = 'https://api.klockner.es/analytics/sessionByDevice/';  // URL to web api
  private SessionByHoursURL = 'https://api.klockner.es/analytics/sessionByHours/';
  
  private httpOptions = {
    headers: new HttpHeaders({
      'Authorization': 'Bearer '+this.cookieService.get("admin_token"),
    })
  };

  constructor(
    private http: HttpClient,
    private cookieService: CookieService 
  ){

  }
    
  getAnalyticsSessions (days){
    return this.http.get<any>(this.sessionsURL + days, this.httpOptions)
    .pipe(
      //tap(_ => console.log(_)
      //)
    );
  }

  getAnalyticsBounceRate (days){
    return this.http.get<any>(this.bounceRateURL + days, this.httpOptions)
    .pipe(
      tap(_ => {
        console.log('bounce', _)
      })
    );
  }

  getPagePath (days){
    return this.http.get<any>(this.pagePathURL + days, this.httpOptions)
    .pipe(
      //tap(_ => console.log(_)
      //)
    );
  }

  getDeviceConnections(days){
    return this.http.get<any>(this.DeviceConnectionURL + days, this.httpOptions)
    .pipe(
      //tap(_ => console.log(_)
      //)
    );
  }
  getSessionsByHours(days){
    return this.http.get<any>(this.SessionByHoursURL + days, this.httpOptions)
    .pipe(
      //tap(_ => console.log(_)
      //)
    );
  }

    
  
}

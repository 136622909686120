import { Component, OnInit } from '@angular/core';
import { ProductoService } from "../utilities/servicios/producto.service";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  productosDestacados: any = [];
  index: any;
  config: any;

  slidePrincipalConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    infinite: true,
    autoplay: false,
    adaptiveHeight: false,
    dots: false,
    fade: true,
    cssEase: 'linear',
  };

  constructor(
    private productoService: ProductoService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    this.getDestacados();
  }

  getDestacados() {
    this.productoService.getDestacados()
      .subscribe(data => {
        this.productosDestacados = data.productos.filter((items) => items.active === true);
      });
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }
}

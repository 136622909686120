import { Component, OnInit } from '@angular/core';
import { EventoService } from "../utilities/servicios/evento.service";
import { PonenteService } from "../utilities/servicios/ponente.service";
import { GlobalUtilities } from '../utilities/clases/global_utilities';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import mapStyle from './mapStyle.json';
import { MatSnackBar } from '@angular/material';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-detalle-evento',
  templateUrl: './detalle-evento.component.html',
  styleUrls: ['./detalle-evento.component.scss']
})
export class DetalleEventoComponent implements OnInit {
  evento: any;
  eventos: any;
  ponentes: any;
  titulo: any;
  styles = mapStyle;
  ciudades = [];
  term: any;
  selectedCiudad;
  selectedPais;
  selectedPonente;
  selectedStartDate;
  selectedEndDate;

  constructor(
    private eventoService: EventoService,
    private utilities: GlobalUtilities,
    private snackBar: MatSnackBar,
    private ponenteService: PonenteService,
    private route: ActivatedRoute,
    private router: Router,
    private meta: Meta
  ) {
    //Same URL Router Events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(async (event: NavigationEnd) => {
      this.getEventoByTitulo();
      this.getPonentes();
      this.getEventos();
    });
    //END Same URL Router Events
  }

  ngOnInit() {
    this.getEventoByTitulo();
    this.getPonentes();
    this.getEventos();
    this.showFiltros('open');
  }


  getEventoByTitulo() {
    let titulo = this.route.snapshot.paramMap.get('titulo');
    //remove the dashes and add spaces
    if (titulo) titulo = titulo.split('-').join(' ');

    this.eventoService.getEventoByTitulo(String(titulo))
      .subscribe(data => {
        if (data.evento.length > 0 && data.evento[0].activo) {
          this.evento = data.evento[0];
          this.eventoService.getCoordinates(this.evento.ubicacion.direccion)
            .subscribe(data => {
              this.evento.lat = data.results[0].geometry.location.lat;
              this.evento.long = data.results[0].geometry.location.lng;
            })
        } else {
          window.location.href = '/404';
        }
      });
  }

  getEventos() {
    this.term = "";
    this.selectedCiudad = "";
    this.selectedPais = "";
    this.selectedPonente = "";
    this.selectedStartDate = "";
    this.selectedEndDate = "";

    this.eventoService.getEventos()
      .subscribe(data => {
        this.eventos = data.eventos.filter((items) => items.activo === true);
        this.eventos.forEach(evento => {
          this.ciudades.indexOf(evento.ubicacion.poblacion) === -1 ? this.ciudades.push(evento.ubicacion.poblacion) : '';
        });

        this.meta.updateTag({ property: 'og:author', content: 'Klockner' });
        this.meta.updateTag({ property: 'og:description', content: this.eventos[0].descripcion });
        this.meta.updateTag({ property: 'og:type', content: 'article' });
        this.meta.updateTag({ property: 'og:title', content: this.eventos[0].titulo });
        this.meta.updateTag({ property: 'og:url', content: this.eventos[0].titulo });
        this.meta.updateTag({ property: 'og:image', content: 'https://api.klockner.es' + this.eventos[0].photo[0] });

        this.meta.updateTag({ property: 'twitter:card', content: 'summary' });
        this.meta.updateTag({ property: 'twitter:site', content: '@klocknerES' });
        this.meta.updateTag({ property: 'twitter:creator', content: 'Klockner' });
        this.meta.updateTag({ property: 'twitter:description', content: this.eventos[0].descripcion });
        this.meta.updateTag({ property: 'twitter:image”', content: 'https://api.klockner.es' + this.eventos[0].photo[0] });
        this.meta.updateTag({ property: 'twitter:url', content: this.eventos[0].titulo });
        this.meta.updateTag({ property: 'twitter:title', content: this.eventos[0].titulo });
      });
  }

  getPonentes() {
    this.ponenteService.getPonentes()
      .subscribe(data => {
        this.ponentes = data.ponentes;
      });
  }

  searchEventos() {
    var term = this.term;

    console.log(term);

    if (term == "")
      return this.openSnackBar('¡Ups! Debes escribir qué quieres buscar.', 'Aceptar');

    this.eventoService.searchEventos(term)
      .subscribe(data => {
        //Enviar a eventos general
        this.router.navigate(['eventos'], { state: { buscadorEventos: { data } } });
      });
  }

  filtrarEventos() {
    var filters = JSON.stringify({
      term: this.term,
      ciudad: this.selectedCiudad,
      pais: this.selectedPais,
      ponente: this.selectedPonente,
      startDate: this.selectedStartDate,
      endDate: this.selectedEndDate
    });

    if (this.selectedCiudad == "" && this.selectedPais == "" && this.selectedPonente == "" && this.selectedStartDate == "" && this.selectedEndDate == "")
      return this.openSnackBar('¡Ups! Debes seleccionar algún filtro para buscar.', 'Aceptar');

    this.eventoService.filterEventos(filters)
      .subscribe(data => {
        //Enviar a eventos general
        this.router.navigate(['eventos'], { state: { buscadorEventos: { data } } });
      });
  }

  showFiltros(event) {
    if (event == 'open') {
      $("#filtrosBuscadorEventos").slideDown("500", function () { });
    } else {
      $("#filtrosBuscadorEventos").slideUp("500", function () { });
    }
    $(".buscadorEventosTrigger").toggle();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  navigateToRecipeDetails(name: String) {
    //Add dashes in place of spaces
    let nameInRoute: String = name.split(' ').join('-');
    this.router.navigate([nameInRoute]);
  }
}

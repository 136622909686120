import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AnalyticsService } from "../../utilities/servicios/analytics.service";
import { AuthService } from "../../utilities/servicios/auth.service";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  constructor(
    private AnalyticsService: AnalyticsService,
    private cdRef: ChangeDetectorRef,
    private auth: AuthService
  ) {

  }

  ngOnInit() {
    this.getAnalyticsSessions();
    this.getAnalyticsgetBounceRate();
    this.getPagePath();
    this.getConnectionsByHour();
    this.getDeviceConnections();
  }


  // START CHARTS
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  //SESSION CHART
  selectedSessiones = "30"
  public AnalyticsSessionChartType: string = 'line';
  public AnalyticsSessionChartDatasets: Array<any>;
  public AnalyticsSessionChartLabels: Array<any>;
  public AnalyticsSessionChartOptions: any = {
    responsive: true
  };
  public AnalyticsSessionChartolors: Array<any> = [
    {
      borderColor: 'black',
      backgroundColor: 'rgb(0,0,0,0.1)',
      pointBackgroundColor: 'black',
      borderWidth: 1,
    }
  ];
  AnalyticsSessionsDate = [];
  AnalyticsSessionsData = [];

  getAnalyticsSessions(): void {
    this.AnalyticsService.getAnalyticsSessions(this.selectedSessiones)
      .subscribe(data => {
        this.AnalyticsSessionChartDatasets = [];
        this.AnalyticsSessionChartLabels = [];
        this.AnalyticsSessionsDate = [];
        this.AnalyticsSessionsData = [];
        data.forEach(element => {
          let date = element.dimensions[0].slice(6) + '-' + element.dimensions[0].slice(4, 6) + '-' + element.dimensions[0].slice(0, 4)
          this.AnalyticsSessionsDate.push(date)
          this.AnalyticsSessionsData.push(element.metrics[0].values[0])
        });
        this.AnalyticsSessionChartDatasets = [
          { data: this.AnalyticsSessionsData, label: 'Sesiones' }
        ]
        this.AnalyticsSessionChartLabels = this.AnalyticsSessionsDate;
      }, err =>{
        if(err.status == 401){
          this.auth.logout(true);
        }
      });
  }
  //END SESSION CHART

  //BOUNCE RATE CHART
  selectedRebotes = "30"
  public AnalyticsBounceRateChartType: string = 'bar';
  public AnalyticsBounceRateChartDatasets: Array<any>;
  public AnalyticsBounceRateChartLabels: Array<any>;
  public AnalyticsBounceRateChartOptions: any = {
    responsive: true
  };
  public AnalyticsBounceRateChartolors: Array<any> = [
    {
      borderColor: 'black',
      backgroundColor: 'rgb(0,0,0,0.1)',
      pointBackgroundColor: 'black',
      borderWidth: 1,
    }
  ];
  AnalyticsBounceRateDate = [];
  AnalyticsBounceRateData = [];

  getAnalyticsgetBounceRate(): void {
    this.AnalyticsService.getAnalyticsBounceRate(this.selectedRebotes)
      .subscribe(data => {
        this.AnalyticsBounceRateChartDatasets = [];
        this.AnalyticsBounceRateChartLabels = [];
        this.AnalyticsBounceRateDate = [];
        this.AnalyticsBounceRateData = [];
        data.forEach(element => {
          let date = element.dimensions[0].slice(6) + '-' + element.dimensions[0].slice(4, 6) + '-' + element.dimensions[0].slice(0, 4)
          this.AnalyticsBounceRateDate.push(date)
          this.AnalyticsBounceRateData.push(Math.round(element.metrics[0].values[0] * 100) / 100)
        });
        this.AnalyticsBounceRateChartDatasets = [
          { data: this.AnalyticsBounceRateData, label: 'Tasa de Rebote' }
        ]
        this.AnalyticsBounceRateChartLabels = this.AnalyticsBounceRateDate;
      }, err =>{
        if(err.status == 401){
          this.auth.logout(true);
        }
      });
  }
  //END BOUNCE RATE CHART

  //DEVICE CONNECTION CHART
  selectedDispositivos = "30"
  public DeviceConnectionChartType: string = 'doughnut';
  public DeviceConnectionChartDatasets: Array<any>;
  public DeviceConnectionChartLabels: Array<any>;
  public DeviceConnectionChartOptions: any = {
    responsive: true,
    legend: {
      position: 'bottom'
    }
  };
  public DeviceConnectionChartColors: Array<any> = [
    {
      borderColor: 'black',
      backgroundColor: ['rgb(0,0,0,0.1)', 'rgb(0,0,0,0.3)', 'rgb(0,0,0,0.6)', 'rgb(0,0,0,0.9)'],
      pointBackgroundColor: 'black',
      borderWidth: 1
    }
  ];
  DeviceConnectionBounceRateDate = [];
  DeviceConnectionBounceRateData = [];

  getDeviceConnections(): void {
    this.AnalyticsService.getDeviceConnections(this.selectedDispositivos)
      .subscribe(data => {
        this.DeviceConnectionChartDatasets = [];
        this.DeviceConnectionChartLabels = [];
        this.DeviceConnectionBounceRateDate = [];
        this.DeviceConnectionBounceRateData = [];
        data.forEach(element => {
          this.DeviceConnectionBounceRateDate.push(element.dimensions[0])
          this.DeviceConnectionBounceRateData.push(element.metrics[0].values[0])
        });
        this.DeviceConnectionChartDatasets = [
          { data: this.DeviceConnectionBounceRateData, label: 'Dispositivos' }
        ]
        this.DeviceConnectionChartLabels = this.DeviceConnectionBounceRateDate;
      }, err =>{
        if(err.status == 401){
          this.auth.logout(true);
        }
      });
  }
  //END DEVICE CONNECTION CHART
  // END CHARTS


  // FUNCIONES PARA PODER BUSCAR EN LAS TABLAS
  AnalyticsPagePathApplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.AnalyticsPagePathdataSource.filter = filterValue;
  }
  // END FUNCIONES PARA PODER BUSCAR EN LAS TABLAS


  // TABLA ANALYTICS PAGE PATH
  AnalyticsPagePathdisplayedColumns: string[] = ['pagina', 'visitas'];
  AnalyticsPagePathdataSource: MatTableDataSource<any>;

  @ViewChild('AnalyticsPagePathPaginator') AnalyticsPagePathPaginator: MatPaginator;
  @ViewChild('AnalyticsPagePathSort') AnalyticsPagePathSort: MatSort;

  getPagePath(): void {
    this.AnalyticsService.getPagePath(30)
      .subscribe(data => {
        //PERMITIR FILTRAR COLUMNAS DEL POPULATE
        data.forEach(element => {
          element.pagina = element.dimensions
          element.visitas = element.metrics[0].values
        });

        this.AnalyticsPagePathdataSource = new MatTableDataSource(data);
        this.AnalyticsPagePathdataSource.paginator = this.AnalyticsPagePathPaginator;
        this.AnalyticsPagePathdataSource.sort = this.AnalyticsPagePathSort;
      }, err =>{
        if(err.status == 401){
          this.auth.logout(true);
        }
      });
  }
  // END TABLA ANALYTICS PAGE PATH

  //GET CONNECTIONS BY HOURS
  tiles: any = [];
  selectedUsuarios = "30"
  getConnectionsByHour() {
    this.AnalyticsService.getSessionsByHours(this.selectedUsuarios).subscribe(data => {
      this.tiles = [];
      var objDeHoras = {
        1: {},
        2: {},
        3: {},
        4: {},
        5: {},
        6: {},
        7: {},
      };
      data.forEach(metrica => {
        let diaSemana = parseInt(metrica.dimensions[0]) + 1 ;
        let horaDelDia = metrica.dimensions[1]
        if (horaDelDia[0] == 0) horaDelDia = horaDelDia[1]
        objDeHoras[diaSemana.toString()][horaDelDia] = metrica.metrics[0].values[0]
      })
      var hours = 0;
      for (var altura = 1; altura < 27; altura++) {
        for (var profundidad = 1; profundidad < 9; profundidad++) {
          var texto;
          var obj;
          if (altura == 26) {
            switch (profundidad) {
              case 1: texto = "lun."; break;
              case 2: texto = "mar."; break;
              case 3: texto = "mié."; break;
              case 4: texto = "jue."; break;
              case 5: texto = "vie."; break;
              case 6: texto = "sáb."; break;
              case 7: texto = "dom."; break;
              default: texto = "";
            }
            obj = { text: texto, tooltip: "", cols: 1, rows: 1, color: 'white' }
          }
          if (profundidad == 8) {
            if (altura % 2 != 0) {
              var usoHorario = hours < 12 ? " a.m." : " p.m.";
              obj = { text: hours + usoHorario, tooltip: "", cols: 1, rows: 1, color: 'white' }
              hours += 2;
            } else {
              obj = { text: "", tooltip: "", cols: 1, rows: 1, color: 'white' }
            }
          }
          if (altura < 26 && profundidad < 8) {
            if (objDeHoras[profundidad][altura - 1] != undefined) {
              let c = "rgb(0,0,0,0." + (parseInt(objDeHoras[profundidad][altura - 1]) + 2) + ")"
              obj = { text: "", tooltip: objDeHoras[profundidad][altura - 1] + " visitas", cols: 1, rows: 1, color: c }
            } else {
              obj = { text: "", tooltip: "No hay visitas", cols: 1, rows: 1, color: 'rgb(0,0,0,0.1)' }
            }

          }
          this.tiles.push(obj)
        }
      }
    }, err => { })
  }
  //END GET CONNECTIONS BY HOURS
}

import { Component, Inject, PLATFORM_ID, OnInit } from '@angular/core';
import { AuthService } from '../../../utilities/servicios/auth.service';
import { HostListener } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, ActivationEnd, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  mobileGrid;
  isBrowser;
  titulo : any;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private auth: AuthService,
    private router: Router,
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.getScreenSizeStart();
      this.getScreenSize();
    }

    //Check Session Router Events
    router.events.pipe(
      filter(event => event instanceof ActivationEnd)  
    ).subscribe(async (event: ActivationEnd) => {
      this.titulo = event.snapshot.data['title'];
    });
    //END Check Session Router Events
  }

  ngOnInit() {
  }

  // Grid Events
  scrHeight: any;
  scrWidth: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 768)
      this.mobileGrid = true;
    else
      this.mobileGrid = false;
  }

  getScreenSizeStart() {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    if (this.scrWidth <= 768) this.mobileGrid = true;
    else this.mobileGrid = false;
  }


  admin_logout(){
    this.auth.logout(true);
  }
}

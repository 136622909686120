import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';

// Angular Material
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { SharedModule } from './shared.module';

// NGX Material File Input
import { MaterialFileInputModule } from 'ngx-material-file-input';

// NGX ScrollTo
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

// TimePicker (ERROR CON SRR)
import { AmazingTimePickerModule } from 'amazing-time-picker';

// MDB BOOTSTRAP
import { MDBBootstrapModule } from 'angular-bootstrap-md';

// Utilidades creadas por nosotros
import { GlobalUtilities } from './utilities/clases/global_utilities';
import { sanitizeHtmlPipe } from './utilities/servicios/custom-pipes/sanitize-html.pipe';
import { SafePipe } from './utilities/servicios/custom-pipes/safe.pipe';
import { DotDotDotPipe } from './utilities/servicios/custom-pipes/dotdotdot.pipe';
import { DatePipe } from '@angular/common';
import { RunScriptsDirective } from './utilities/directivas/RunScriptsDirective';

// Cookies
import { CookieService } from 'ngx-cookie-service';

//pagination
import { NgxPaginationModule } from 'ngx-pagination';

// importar locales de idiomas
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePy from '@angular/common/locales/es-PY';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en')

// WYSIWYG
import { AngularEditorModule } from '@kolkov/angular-editor';

// Angular Google Maps
import { AgmCoreModule } from '@agm/core';

// Componentes Public
import { HeaderComponent, advertenciaDialog } from './partials/header/header.component';
import { FooterComponent, texto_legal_dialog } from './partials/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactoComponent, texto_legal_dialog_contacto } from './contacto/contacto.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PartialContactoComponent, texto_legal_dialog_partial_contacto } from './partials/partial-contacto/partial-contacto.component';
import { MenuComponent } from './partials/menu/menu.component';
import { BlogComponent } from './blog/blog.component';
import { DetalleBlogComponent } from './detalle-blog/detalle-blog.component';
import { PaginasComponent } from './paginas/paginas.component';
import { FamiliaComponent } from './familia/familia.component';
import { ResultadosBuscadorComponent } from './resultados-buscador/resultados-buscador.component';
import { CtaContactoComponent } from './partials/cta-contacto/cta-contacto.component';
import { DetalleProductoComponent, ctaFichaTecnicaDialog } from './detalle-producto/detalle-producto.component';
import { PartialBlogComponent } from './partials/partial-blog/partial-blog.component';
import { ProductosSolucionesComponent } from './partials/productos-soluciones/productos-soluciones.component';
import { EventosComponent } from './eventos/eventos.component';
import { DetalleEventoComponent } from './detalle-evento/detalle-evento.component';
import { PartialEventosComponent } from './partials/partial-eventos/partial-eventos.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { CienciaComponent } from './ciencia/ciencia.component';
import { BibliotecaComponent } from './biblioteca/biblioteca.component';

//Componentes Admin
import { AdminLoginComponent } from './admin/login/login.component';
import { AdminHomeComponent } from './admin/home/home.component';
import { AdminContactoComponent, fullMessageDialog } from './admin/contacto/contacto.component';
import { ListadoProductoComponent } from './admin/producto/listado-producto/listado-producto.component';
import { NewProductoComponent, addRelatedDialogNewProd, addCasoClinicoDialogNewProd } from './admin/producto/new-producto/new-producto.component';
import { EditProductoComponent, addRelatedDialog, addCasoClinicoDialog } from './admin/producto/edit-producto/edit-producto.component';
import { ListadoBlogComponent } from './admin/blog/listado-blog/listado-blog.component';
import { NewBlogComponent, addRelatedDialogNewBlog } from './admin/blog/new-blog/new-blog.component';
import { EditBlogComponent, addRelatedDialogEditBlog } from './admin/blog/edit-blog/edit-blog.component';
import { ListadoPaginasComponent } from './admin/paginas/listado-paginas/listado-paginas.component';
import { EditPaginasComponent } from './admin/paginas/edit-paginas/edit-paginas.component';
import { NewPaginasComponent } from './admin/paginas/new-paginas/new-paginas.component';
import { EditEventoComponent } from './admin/evento/edit-evento/edit-evento.component';
import { NewEventoComponent } from './admin/evento/new-evento/new-evento.component';
import { ListadoEventoComponent } from './admin/evento/listado-evento/listado-evento.component';
import { ListadoPonenteComponent } from './admin/ponente/listado-ponente/listado-ponente.component';
import { NewPonenteComponent } from './admin/ponente/new-ponente/new-ponente.component';
import { EditPonenteComponent } from './admin/ponente/edit-ponente/edit-ponente.component';
import { ListadoCasoClinicoComponent } from './admin/caso-clinico/listado-caso-clinico/listado-caso-clinico.component';
import { NewCasoClinicoComponent } from './admin/caso-clinico/new-caso-clinico/new-caso-clinico.component';
import { EditCasoClinicoComponent } from './admin/caso-clinico/edit-caso-clinico/edit-caso-clinico.component';
import { NewDescargaComponent } from './admin/descarga/new-descarga/new-descarga.component';
import { EditDescargaComponent } from './admin/descarga/edit-descarga/edit-descarga.component';
import { ListadoDescargaComponent } from './admin/descarga/listado-descarga/listado-descarga.component';
import { NewFamiliaComponent } from './admin/familia/new-familia/new-familia.component';
import { EditFamiliaComponent } from './admin/familia/edit-familia/edit-familia.component';
import { ListadoFamiliaComponent } from './admin/familia/listado-familia/listado-familia.component';
import { EditMenuComponent } from './admin/menu/edit-menu/edit-menu.component';
import { ListadoFaqsComponent } from './admin/faqs/listado-faqs/listado-faqs.component';
import { EditFaqComponent } from './admin/faqs/edit-faq/edit-faq.component';
import { NewFaqComponent } from './admin/faqs/new-faq/new-faq.component';

// Componentes dinámicos
import { DynamicHTMLModule } from 'ng-dynamic'

//Componentes Error
import { NotFoundComponent } from './not-found/not-found.component';

//Animaciones Lottie
import { LottieAnimationViewModule } from 'ng-lottie';
import { PlaygroundComponent } from './playground/playground.component';

// Zendesk
import { ngxZendeskWebwidgetModule, ngxZendeskWebwidgetConfig } from 'ngx-zendesk-webwidget';
export class ZendeskConfig extends ngxZendeskWebwidgetConfig {
  accountUrl = 'https://klockner.zendesk.com';
  beforePageLoad(zE) {
    zE.setLocale('es');
    zE.hide();
  }
}

// Cargar Scripts
import { LazyLoadScriptService } from './utilities/servicios/lazy-load-script.service';
import { SidenavComponent } from './admin/partials/sidenav/sidenav.component';

// Slick Carousel
import { SlickCarouselModule } from 'ngx-slick-carousel';

// Skeleton Loader
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// On Viewport
import { InViewportModule } from 'ng-in-viewport';

@NgModule({
  declarations: [
    AppComponent,
    advertenciaDialog,
    AdminLoginComponent,
    sanitizeHtmlPipe,
    SafePipe,
    DotDotDotPipe,
    RunScriptsDirective,
    AdminHomeComponent,
    AdminContactoComponent,
    fullMessageDialog,
    ListadoProductoComponent,
    NewProductoComponent,
    addRelatedDialogNewProd,
    addRelatedDialogNewBlog,
    addRelatedDialogEditBlog,
    EditProductoComponent,
    addRelatedDialog,
    addCasoClinicoDialog,
    addCasoClinicoDialogNewProd,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    ListadoBlogComponent,
    NewBlogComponent,
    EditBlogComponent,
    ListadoPaginasComponent,
    EditPaginasComponent,
    NewPaginasComponent,
    PaginasComponent,
    ContactoComponent,
    ThankYouComponent,
    FaqsComponent,
    PartialContactoComponent,
    MenuComponent,
    BlogComponent,
    DetalleBlogComponent,
    PartialBlogComponent,
    ProductosSolucionesComponent,
    EditEventoComponent,
    NewEventoComponent,
    ListadoEventoComponent,
    EventosComponent,
    DetalleEventoComponent,
    PartialEventosComponent,
    ListadoPonenteComponent,
    NewPonenteComponent,
    EditPonenteComponent,
    CtaContactoComponent,
    DetalleProductoComponent,
    ctaFichaTecnicaDialog,
    ListadoCasoClinicoComponent,
    NewCasoClinicoComponent,
    EditCasoClinicoComponent,
    FamiliaComponent,
    texto_legal_dialog,
    texto_legal_dialog_contacto,
    texto_legal_dialog_partial_contacto,
    ResultadosBuscadorComponent,
    ListadoDescargaComponent,
    NewDescargaComponent,
    EditDescargaComponent,
    ListadoDescargaComponent,
    ListadoFamiliaComponent,
    NewFamiliaComponent,
    EditFamiliaComponent,
    EditMenuComponent,
    PlaygroundComponent,
    SidenavComponent,
    EmpresaComponent,
    CienciaComponent,
    BibliotecaComponent,
    ListadoFaqsComponent,
    EditFaqComponent,
    NewFaqComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ngxZendeskWebwidgetModule.forRoot(ZendeskConfig),
    LayoutModule,
    AppRoutingModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    MDBBootstrapModule.forRoot(),
    MaterialFileInputModule,
    ScrollToModule.forRoot(),
    // Añadir los componentes que van a usarse como widgets
    DynamicHTMLModule.forRoot({
      components: [
        { component: PartialContactoComponent, selector: 'app-partial-contacto' },
      ]
    }),
    // Api Key Google Maps
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA8zB93MrNmuHKG0DbllBO9Y_svpoIkD5w'
    }),
    SlickCarouselModule,
    NgxSkeletonLoaderModule,
    InViewportModule,
    // Animaciones Lottie (ERROR CON SRR)
    // LottieAnimationViewModule.forRoot(),
    // TimePicker (ERROR CON SRR)
    // AmazingTimePickerModule,
  ],
  providers: [
    GlobalUtilities,
    CookieService,
    DatePipe,
    {
      provide: LOCALE_ID,
      useValue: 'es'
    },
    { provide: 'isBrowser', useValue: true },
    LazyLoadScriptService
  ],
  entryComponents: [
    AdminContactoComponent,
    fullMessageDialog,
    addRelatedDialog,
    addCasoClinicoDialog,
    addCasoClinicoDialogNewProd,
    addRelatedDialogNewProd,
    addRelatedDialogNewBlog,
    addRelatedDialogEditBlog,
    texto_legal_dialog,
    texto_legal_dialog_contacto,
    texto_legal_dialog_partial_contacto,
    ctaFichaTecnicaDialog,
    advertenciaDialog,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

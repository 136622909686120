import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ciencia',
  templateUrl: './ciencia.component.html',
  styleUrls: ['./ciencia.component.scss']
})
export class CienciaComponent implements OnInit {

  constructor() { }

  slidePrincipalConfig: any = {
    fade: true,
    cssEase: 'linear',
    speed: 500,  // this is the line that matters
  };

  ngOnInit() {
    
  }

}
